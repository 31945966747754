import { Injectable } from '@angular/core';
import { ServiciosDataService } from '../providers/servicios-data.service';

@Injectable({
  providedIn: 'root'
})
export class PasajeAsientosDataService {

	public lista:any;

	constructor(
		public serviciosData : ServiciosDataService,
	) {

	}

	public cargarAsientos(id) {
		this.serviciosData.cargar(
			'seat_map',
			'ws_seat_map',
			`<id>${id}</id>`
		)
	}

}
