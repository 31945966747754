import { ServiciosDataService } from 'src/app/comprar-pasaje/providers/servicios-data.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { LoginService } from 'src/app/core/login/login.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { WebServices } from 'src/app/providers/webServices';
import { fadeWindow } from '../../providers/animations'
import { Router } from '@angular/router';
import { MatPaginator, MatTableDataSource } from '@angular/material';

export interface StructTable {
  tipoventa: string;
  idpago: number;
  boleto: number;
  origen: string;
  destino: string,
  fechahorasalida: string,
  urldescarga: string
}


@Component({
  selector: 'usuario',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.scss'],
  animations: [fadeWindow]
})
export class PerfilComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = ['tipoventa'];

  texto_sin_data = "Cargando...";

  ELEMENT_DATA: any = [];
  ELEMENT_DATA_2: any = [];

  dataSource = new MatTableDataSource<StructTable>(this.ELEMENT_DATA);
  dataSource_2 = new MatTableDataSource<StructTable>(this.ELEMENT_DATA_2);

  edit: boolean = false;

  usuarioData = JSON.parse(localStorage.getItem('dataSession')).data;
  nombre = `${this.usuarioData.nombre} ${this.usuarioData.apellido}`;

  editando: boolean = false;

  usuarioInfo: any;
  boletoActual: any;

  passwordEdition: FormGroup = this.formBuilder.group({
    password: ['', [Validators.minLength(8)]],
    confirmPassword: ['', [Validators.minLength(8)]],
  }, { validator: ValidadorPassword.matchPassword.bind(this) })


  formEdicion: FormGroup = this.formBuilder.group({
    name: ['', [Validators.required]],
    idDocument: ['', Validators.compose([Validators.required, Validators.minLength(9)])],
    phone: ['', Validators.compose([Validators.required, Validators.minLength(9), Validators.pattern("[0-9]+")])],
    email: ['', [Validators.required, Validators.email]],
    passwordEditionGroup: this.passwordEdition
  });

  formAnular: FormGroup = this.formBuilder.group({
    ticketid: ['', [Validators.required]],
    nombres: ['', [Validators.required]],
    rut: ['', [Validators.required]],
    banco: ['', [Validators.required]],
    tipo_cuenta: ['', [Validators.required]],
    numero_cuenta: ['', [Validators.required]],
    idpagoptur: ['', [Validators.required]],
  });


  bancos: any = [
    { cod_banco: 1, banco: "BANCO DE CHILE" },
    { cod_banco: 1, banco: "BANCO EDWARDS" },
    { cod_banco: 1, banco: "CITIBANK" },
    { cod_banco: 1, banco: "ATLAS" },
    { cod_banco: 1, banco: "CREDI CHILE" },
    { cod_banco: 9, banco: "BANCO INTERNACIONAL" },
    { cod_banco: 12, banco: "BANCO ESTADO" },
    { cod_banco: 14, banco: "SCOTIABANK" },
    { cod_banco: 16, banco: "BANCO CREDITO E INVERSIONES (BCI)" },
    { cod_banco: 16, banco: "TBANC" },
    { cod_banco: 16, banco: "BANCO NOVA" },
    { cod_banco: 27, banco: "CORPBANCA" },
    { cod_banco: 27, banco: "BANCO CONDELL" },
    { cod_banco: 28, banco: "BANCO BICE" },
    { cod_banco: 31, banco: "HSBC BANK" },
    { cod_banco: 37, banco: "BANCO SANTANDER" },
    { cod_banco: 39, banco: "BANCO ITAU" },
    { cod_banco: 49, banco: "BANCO SECURITY" },
    { cod_banco: 51, banco: "BANCO FALABELLA" },
    { cod_banco: 52, banco: "DEUTSHE BANK" },
    { cod_banco: 53, banco: "BANCO RIPLEY" },
    { cod_banco: 54, banco: "RABOBANK CHILE (EX HNS BANCO)" },
    { cod_banco: 55, banco: "BANCO CONSORCIO" },
    { cod_banco: 57, banco: "BANCO PENTA" },
    { cod_banco: 504, banco: "BANCO PARIS" },
    { cod_banco: 504, banco: "BBVA" },
    { cod_banco: 504, banco: "BANCO BBVA EXPRESS" },
    { cod_banco: 671, banco: "COOCRETAL" },
    { cod_banco: 672, banco: "COOPEUCH" }
  ];

  tipoCuenta: any = [
    { nombre: 'Cta. Vista' },
    { nombre: 'Cta. Cte' },
    { nombre: 'Cta. Ahorro' }
  ];

  constructor(
    public login: LoginService,
    private modal: NgbModal,
    private webServices: WebServices,
    private formBuilder: FormBuilder,
    private router: Router,
    private serviciosData: ServiciosDataService
  ) { }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource_2.paginator = this.paginator;

    if (!this.login.sessionIniciada) {

      this.router.navigate(['/']);
    }

    else {
      this.cargarInfoUsuario();
    }
  }

  editar() {

    this.editando = true;
    this.disableFormControls()

    let data_a_enviar = {
      "nombre": this.formEdicion.controls['name'].value,
      "apellido": this.formEdicion.controls['lastname'].value,
      "idDocumento": this.formEdicion.controls['idDocument'].value,
      "telefono": this.formEdicion.controls['phone'].value,
      "idsesion": this.webServices.idSesion,
      "password": this.passwordEdition.controls.password.value,
      "confirmPassword": this.passwordEdition.controls.confirmPassword.value
    }

  }

  cerrar() { this.modal.dismissAll() }

  cargarInfoUsuario = () => {
    this.usuarioInfo = JSON.parse(localStorage.getItem('dataSession'))['data'][0];
    this.usuarioInfo['km'] = 0;
    this.formEdicion.controls['name'].setValue(this.usuarioInfo.nombre);
    this.formEdicion.controls['idDocument'].setValue(this.usuarioInfo.rutcliente);
    this.formEdicion.controls['phone'].setValue(this.usuarioInfo.telefono);
    this.formEdicion.controls['email'].setValue(this.usuarioInfo.email);


    // km
    this.serviciosData.cargar('get_km',
      'ws_km',
      `<rutcliente>${this.usuarioInfo.rutcliente}</rutcliente>`,
      null,
      (response) => {
        this.usuarioInfo['km'] = response[0].km
      });

    // boletos no anulados :v
    this.serviciosData.cargar('get_tickets',
      'get_tickets',
      `<rutcliente>${this.usuarioInfo.rutcliente}</rutcliente>`,
      // `<rutcliente>17743052</rutcliente>`,
      null,
      (response) => {
        if (response != "No hay boletos comprados") {
          this.ELEMENT_DATA = response;



          this.dataSource = new MatTableDataSource<StructTable>(this.ELEMENT_DATA);
          this.dataSource.paginator = this.paginator;
        }
        this.texto_sin_data = "No hay boletos comprados";
      }, () => {

      });


    // boletos anulados
    this.serviciosData.cargar('get_tickets',
      'get_tickets_anulados',
      `<correo>${this.usuarioInfo.correo}</correo>`,
      null,
      (response) => {


        console.log(response);
        if (response == 'No hay boletos comprados') {
          this.dataSource_2 = null;
        } else {
          this.ELEMENT_DATA_2 = response;
          this.dataSource_2 = new MatTableDataSource<StructTable>(this.ELEMENT_DATA_2);
          this.dataSource_2.paginator = this.paginator;
        }
      }, () => {

      });


  }

  disableFormControls() {
    this.formEdicion.controls['name'].disable();
    this.formEdicion.controls['idDocument'].disable();
    this.formEdicion.controls['phone'].disable();
    this.formEdicion.controls['email'].disable();
    this.passwordEdition.controls['password'].disable();
    this.passwordEdition.controls['confirmPassword'].disable();
  }

  enableFormControls() {
    this.formEdicion.controls['name'].enable();
    this.formEdicion.controls['idDocument'].enable();
    this.formEdicion.controls['phone'].enable();
    this.formEdicion.controls['email'].enable();
    this.passwordEdition.controls['password'].enable();
    this.passwordEdition.controls['confirmPassword'].enable();
  }

  abrirModalAnularBoleto(data, template) {
    this.boletoActual = data;
    this.modal.open(template);
    this.formAnular.get("ticketid").setValue(data.boleto);
    this.formAnular.get("idpagoptur").setValue(data.idpago);



  }


  anular() {
    if (!this.formAnular.value.banco || this.formAnular.value.banco.length < 2) {
      this.formAnular.get("banco").setValue("BANCO DE CHILE");
    }

    if (!this.formAnular.value.tipo_cuenta || this.formAnular.value.tipo_cuenta.length < 2) {
      this.formAnular.get("tipo_cuenta").setValue("Cta. Vista");
    }

    let cod_banco: any;
    for (let item of this.bancos) {
      if (item.banco == this.formAnular.value.banco) {
        cod_banco = item.cod_banco;
      }
    }




    this.serviciosData.cargar('ws_cancel', 'cancel',
      `<ticketid>${this.formAnular.value.ticketid}</ticketid>
      <nombre>${this.formAnular.value.nombres}</nombre>
      <rut>${this.formAnular.value.rut}</rut>
      <Banco>${cod_banco}</Banco>
      <tipocuenta>${this.formAnular.value.tipo_cuenta}</tipocuenta>
      <numerocuenta>${this.formAnular.value.numero_cuenta}</numerocuenta>
      <idpagoptur>${this.formAnular.value.idpagoptur}</idpagoptur>
      `,
      null,
      (response) => {

      }, () => {

      });




  }

  cerrarModal() {
    this.modal.dismissAll();
  }

}

class ValidadorPassword {
  static matchPassword(passwordGroup: FormGroup) {
    let password = passwordGroup.controls.password.value;
    let confirmPassword = passwordGroup.controls.confirmPassword.value;

    if (confirmPassword.length <= 0) return null;
    if (confirmPassword !== password) return { doesMatchPassword: true };
    return null;
  }

}
