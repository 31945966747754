import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { LoginService } from 'src/app/core/login/login.service';

@Component({
  selector: 'usuario-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {
	@Output() cerrarComponente = new EventEmitter;

	data = JSON.parse(localStorage.getItem('dataSession')).data[0]

	nombre = `${this.data.nombre}`

  constructor(public login: LoginService) { }

  ngOnInit() {
  }

	cerrar() {
		this.cerrarComponente.emit(true)
	}
}
