import { Injectable } from '@angular/core';
import { LoginService } from '../core/login/login.service';
import { WebServices } from './webServices';
import { ComprarPasajeDataService } from '../comprar-pasaje/providers/comprar-pasaje-data.service';
import { Router } from '@angular/router';
import { ServiciosDataService } from '../comprar-pasaje/providers/servicios-data.service';

@Injectable({
  providedIn: 'root'
})
export class IdSesionService {
  i_session_time;

  idSesion = () => localStorage.getItem('idSesion') ? JSON.parse(localStorage.getItem('idSesion')) : null;
  initialTime = this.idSesion() ? this.idSesion().hora : null;

  constructor(
    public data: ComprarPasajeDataService,
    public router: Router,
    public login: LoginService,
    public webServices: WebServices,
    public serviciosData: ServiciosDataService,
    public idSesionService: IdSesionService,

  ) { }

  crearSesionID() {
    this.serviciosData.cargar(
      'crear_idsession_V2',
      'ws_create_session',
      '',
      null,
      response => {
        localStorage.setItem('idSesion', JSON.stringify({ id: response, hora: new Date().getTime() }))
      }
    )

    // this.webServices.get('getIdSession', () => {
    //   this.webServices.idSesion = this.webServices._temp_['id']
    //   localStorage.setItem('idSesion', JSON.stringify({ id: this.webServices._temp_.idSesion, hora: new Date().getTime() }))
    // })
  }

  verificarSesionID() {
    if (this.idSesion()) {
      if (this.login.autoInitSession(this.idSesion().hora)) {
        this.webServices.idSesion = this.idSesion().id;
      }
      else {
        this.crearSesionID()
      }
    }

    else {
      this.crearSesionID()
    }

    this.verificarTiempoTotal()
  }


  initSesion() {
    let time = `${new Date().getTime()}`;
    localStorage.setItem('session_timer', time);
  }


  verificarTiempoTotal() {
    this.i_session_time = setInterval(() => {
      let progress = Math.floor((new Date().getTime() - parseInt(this.initialTime)) / 1000);

      if (progress >= 500) {
        this.detenerTiempoTotal();
        this.crearSesionID();
        this.initSesion();
        console.warn('SESION TIME DONE');
      }

    }, 1000)
  }


  detenerTiempoTotal() {
    clearTimeout(this.i_session_time);
    localStorage.removeItem('idsesion');
    localStorage.removeItem('session_timer');
    this.router.navigate(['/comprar-pasaje']);
  }
}
