import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AccesoCliente } from '../../providers/tiposDatos'
import { LoginService } from './login.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WebServices } from 'src/app/providers/webServices';
import { AlertasService } from 'src/app/providers/alertas.service';
import { RegistroComponent } from '../../usuario/registro/registro.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  @Output() cerrarComponente = new EventEmitter;

  regexp_email = /[A-Za-z_\.+]+@[A-Za-z]+\.[A-Za-z]+/
  email_recover_password: any = { data: '', errorMsg: "", isValid: null };

  mensajeRecuperarCuenta: any;

  constructor(
    public login: LoginService,
    public modal: NgbModal,
    public webServices: WebServices,
    public alerta: AlertasService,
  ) { }

  ngOnInit() {
  }

  hora = new Date().toLocaleTimeString();

  async iniciarSesion() {
    this.login.verificarDatos(this.login.formLogin.value)
    const confirm = await localStorage.getItem('dataSession');
    this.cerrar();
  }

  cerrar() {
    this.cerrarComponente.emit(true)
  }

  confirmarValidacion() {
    setTimeout(() => { this.validarEmail() }, 500)
  }

  validarEmail() {
    let campo = this.email_recover_password

    if (campo.data) {
      if (campo.data.match(this.regexp_email)) {
        campo.isValid = true;
        campo.errorMsg = "";
      }

      else {
        campo.isValid = false
        campo.errorMsg = "Coloca un email válido"
      }
    }

    else {
      campo.isValid = false
      campo.errorMsg = "No puede estar vacío"
    }

  }

  redireccionar() {
    window.location.href = "https://venta.busespullmantur.cl/site/recupera_pass.php";
  }

  enviarCorreoRecuperacion() {
    this.alerta.deEspera("Verificando su cuenta...")

    this.webServices.get(`resetPassword/${this.email_recover_password.data}`,
      () => {
        this.alerta.cerrar()

        this.mensajeRecuperarCuenta = this.webServices._temp_;

        if (this.mensajeRecuperarCuenta["Error"]) {
          setTimeout(() => {
            this.alerta.deError(this.mensajeRecuperarCuenta["Error"])
          }, 500)
        }

        else {
          setTimeout(() => {
            this.alerta.deExito(
              "El mensaje de recuperación fue enviado a su correo",
              "Si no lo ve en su bandeja principal, revise su carpeta de spam"
            )
          }, 500)
        }


      }
    )
  }

  abrirRegistroModal() {
    const modalRegister = this.modal.open(RegistroComponent, { size: 'lg' });
    modalRegister.componentInstance.currentModal = modalRegister;

  }
}
