import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ComprarPasajeComponent } from './comprar-pasaje/comprar-pasaje.component';
import { PasajeServiciosComponent } from './comprar-pasaje/pasaje-servicios/pasaje-servicios.component';
import { PasajeServiciosIdaVueltaComponent } from './comprar-pasaje/pasaje-servicios-ida-vuelta/pasaje-servicios-ida-vuelta.component';
import { PasajeAsientosComponent } from './comprar-pasaje/pasaje-asientos/pasaje-asientos.component';
import { PasajePagoComponent } from './comprar-pasaje/pasaje-pago/pasaje-pago.component';
import { PasajeConfirmComponent } from './comprar-pasaje/pasaje-confirm/pasaje-confirm.component';
import { RegistroComponent } from './usuario/registro/registro.component';
import { PerfilComponent } from './usuario/perfil/perfil.component';
import { RecuperarUsuarioComponent } from './usuario/recuperar-usuario/recuperar-usuario.component';


const routes: Routes = [
	{
		path:'',
		redirectTo:'comprar-pasaje',
		pathMatch: 'full'
	},
	{
		path:'comprar-pasaje',
		component: ComprarPasajeComponent,
		children: [
			{ path:'', redirectTo:'p0', pathMatch: 'full' },
			{ path:'p0', component: PasajeServiciosIdaVueltaComponent, },
			{ path:'p1', component: PasajeAsientosComponent, },
			{ path:'p2', component: PasajePagoComponent, },
			{ path:'p3', component: PasajeConfirmComponent, },
			{ path:'**', component: PasajeServiciosIdaVueltaComponent, },
		]
	},
  {
		path:'usuario-registro',
		component: RegistroComponent,
	},
  {
		path:'usuario-perfil',
		component: PerfilComponent,
	},
	{
		path:'recuperar-usuario',
		component: RecuperarUsuarioComponent
	}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash:true})],
  exports: [RouterModule],
})
export class AppRoutingModule {  }
