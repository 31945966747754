import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import xml2js from 'xml2js';
import Swal from 'sweetalert2';
import { AlertasService } from 'src/app/providers/alertas.service';


@Injectable({
  providedIn: 'root'
})

export class ServiciosDataService {
  _temp_: any;
  error: boolean = false;
  errorMensaje: string;

  enElSitio = false;

  headers = new HttpHeaders({
    'Content-Type': 'text/xml',
    'Access-Control-Allow-Methods': 'POST, GET, DELETE, PUT, OPTIONS',
    'Access-Control-Allow-Origin': 'busespullmantur.cl',
    'Access-control-Allow-Headers': 'Content-Type, x-requested-with, Authorization, Access-Control-Allow-Origin',
  });

  postInput = (servicio: string, metodoSOAP: string, params: string) => {
    return `
			<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/">
				<soapenv:Body>
					<${metodoSOAP}>
						<api>PULLM4N_TURCL</api>
						${params}
					</${metodoSOAP}>
				</soapenv:Body>
			 </soapenv:Envelope>
		`
  }

  postInputCancel = (servicio: string, metodoSOAP: string, params: string) => {
    return `
			<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/">
				<soapenv:Body>
					<${metodoSOAP}>
						${params}
            <TOKEN>PULLM4N_TU5_W3B</TOKEN>
					</${metodoSOAP}>
				</soapenv:Body>
			 </soapenv:Envelope>
		`
  }

  url = servicio => `https://ptur-ws-web.azurewebsites.net/ws_external/api/pullmantur/${servicio}.php?WSDL`;

  constructor(public http: HttpClient, public alerta: AlertasService) { }


  cargar(servicio: string, metodoSOAP: string, params: string, viaje?: 'ida' | 'vuelta', callback?: (data?) => void, errorfn?: () => void) {
    let url = this.url(servicio);


    let input: string;

    if (servicio == 'ws_cancel') {
      input = this.postInputCancel(servicio, metodoSOAP, params)
    } else {
      input = this.postInput(servicio, metodoSOAP, params)
    }




    this.http.post(url, input, { headers: this.headers, responseType: 'text' })
      .subscribe(
        data => {
          const dataParsed = JSON.parse(this.xmlToJSON(data)['SOAP-ENV:Envelope']['SOAP-ENV:Body'][`ns1:${metodoSOAP}Response`]['return']['_'])

          const respuesta = dataParsed.resultado[0].response

          if (dataParsed.resultado[0].data) {
            this._temp_ = respuesta;
            callback(respuesta);
            this._temp_ = null;
          }

          else {
            if (servicio != 'stops') {
              if (respuesta != "Asiento tomado o no hay reservas con esta session" && respuesta != "No hay boletos comprados") {
                this.alerta.custom('WARN_Icon.svg', respuesta, '<i class="fas fa-arrow-left"></i> Volver', 'error');
              }
            }
            callback(respuesta);
            if (errorfn) {
              this.errorMensaje = dataParsed.resultado[0].response
              errorfn();
              this.errorMensaje = null;
            }

          }

        },

        (error: HttpErrorResponse) => {

          this.error = true;
          if (errorfn) errorfn();

          if (servicio != 'stops') {
            this.alerta.custom(
              'SAD_Emoji.svg',
              'Error al conectar con Pullman Tur',
              '<i class="fas fa-arrow-left"></i> Volver',
              'error',
              () => {
                this.error = false;

              }
            )
          }

        }
      )


  }


  cargarGET(servicio: string, metodoSOAP: string, callback?: (data) => void, errorfn?: () => void) {
    let url = this.url(servicio);

    this.http.get(url, { headers: this.headers, responseType: 'text' })
      .subscribe(
        data => {
          const dataParsed = JSON.parse(this.xmlToJSON(data)['SOAP-ENV:Envelope']['SOAP-ENV:Body'][`ns1:${metodoSOAP}Response`]['return']['_'])
          const respuesta = dataParsed.resultado[0].response
          if (dataParsed.resultado[0].data) {
            callback(respuesta);
          }

          else {

          }
        },

        (error: HttpErrorResponse) => {

          this.error = true;

          if (errorfn) errorfn()

          this.alerta.custom(
            'SAD_Emoji.svg',
            'Error al conectar con Pullman Tur',
            '<i class="fas fa-arrow-left"></i> Volver',
            'error',
            () => {
              this.error = false;
            }
          )
        }
      )


  }


  xmlToJSON(data) {
    let stringJson

    xml2js.parseString(data, { explicitArray: false }, (error, result) => {
      stringJson = result
    });
    return stringJson;
  }

  serviciosDataReset() {
    // this.seat_map_DATA = {ida:null, vuelta:null};
    // this.seat_reserved = null;
    // this.cancel_seat_DATA = null;
    // this.stops_DATA = {ida:[], vuelta:[]};
    // this.stops_ERROR = null;
  }

}
