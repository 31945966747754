import { Injectable } from '@angular/core';
import { NgbDateParserFormatter, NgbDateStruct, NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';

function padNumber(value: number) {
    if (isNumber(value)) {
        return `0${value}`.slice(-2);
    } else {
        return "";
    }
}

function isNumber(value: any): boolean {
    return !isNaN(toInteger(value));
}

function toInteger(value: any): number {
    return parseInt(`${value}`, 10);
}

@Injectable()
export class FormatoFechaService extends NgbDateParserFormatter {
	parse(value: string): NgbDateStruct {
	        if (value) {
	            const dateParts = value.trim().split('/');
	            if (dateParts.length === 1 && isNumber(dateParts[0])) {
	                return {year: toInteger(dateParts[0]), month: null, day: null};
	            } else if (dateParts.length === 2 && isNumber(dateParts[0]) && isNumber(dateParts[1])) {
	                return {year: toInteger(dateParts[1]), month: toInteger(dateParts[0]), day: null};
	            } else if (dateParts.length === 3 && isNumber(dateParts[0]) && isNumber(dateParts[1]) && isNumber(dateParts[2])) {
	                return {year: toInteger(dateParts[2]), month: toInteger(dateParts[1]), day: toInteger(dateParts[0])};
	            }
	        }
	        return null;
	    }

	    format(date: NgbDateStruct): string {
	        let stringDate: string = "";
	        if(date) {
	            stringDate += isNumber(date.day) ? padNumber(date.day) + "-" : "";
	            stringDate += isNumber(date.month) ? padNumber(date.month) + "-" : "";
	            stringDate += date.year;
	        }
	        return stringDate;
	}
}

@Injectable({
  providedIn: 'root'
})
export class DatepickerEsp extends NgbDatepickerI18n {
  esp = {
    weekdays: ['Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa', 'Do'],
    // months: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dec'],
    months: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
  }

  getWeekdayShortName(weekday: number): string {
    return this.esp.weekdays[weekday - 1]
  }

  getMonthShortName(month: number): string {
    return this.esp.months[month - 1]
  }

  getMonthFullName(month: number): string {
    return this.getMonthShortName(month)
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`
  }


}
