import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import Swal from 'sweetalert2';
import { AlertasService } from './alertas.service';

@Injectable({
	providedIn: 'root'
})
export class WebServices
{
    readonly dominio = 'https://prodactivos.com/PullmanTurWeb/busespullmantur/public/index.php/';
    // public readonly dominio = 'https://localhost:8081/busespullmantur/public/';
    public readonly inicioSesion = this.dominio + 'login';
    public readonly registroUsuario = this.dominio + 'register';
    public readonly detalleUsuario = this.dominio + 'detailUser';
    public readonly editarUsuario = this.dominio + 'editUser';
    public readonly cerrarSesion = this.dominio + 'logout';
    public readonly crearIdSesion = this.dominio + 'getIdSession';
    public readonly restaurarContraseña = this.dominio + 'resetPasword';
	public readonly cambiarContraseña = this.dominio + 'changePassword';

	public enElSitio = false;

		idSesion;
		_temp_:any;
		_temp_post:any;


		constructor(
			private http:HttpClient,
			public alerta:AlertasService
		) {}

		userData = localStorage.getItem('dataSession') ? JSON.parse(localStorage.getItem('dataSession')).data : null;


		public readonly httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };

		public readonly httpOptionsLogged = {
      headers: new HttpHeaders({
        	'Content-Type':  'application/json',
			'Authorization': `Bearer ${this.userData ? this.userData['token'] : ''}`,
      })
    };

		get(servicio:string, callback:()=>void, logged?:boolean, err?:()=>void) {
			const userData = localStorage.getItem('dataSession') ? JSON.parse(localStorage.getItem('dataSession')).data : null;

			const headers = logged ? new HttpHeaders({
        // 'Content-Type':  'application/json',
				'Authorization': `Bearer ${userData ? userData['token'] : ''}`,
      }) : new HttpHeaders({ 'Content-Type':  'application/json' })

			this.http.get(this.dominio + servicio, {headers: headers})
			.subscribe(
				data => {
					this._temp_ = data;
					callback();
					this._temp_ = null;
				},
				(error) => {

					if(err) err();

					this.alerta.custom(
            'SAD_Emoji.svg',
            'No hemos podido conectarnos con Pullman Tur',
            '<i class="fas fa-arrow-left"></i> Intenta de nuevo',
            'error'
          )
				}
			)
		}

		post(servicio:string, data_a_enviar:any, callback:()=>void, err?:()=>void) {
			const userData = JSON.parse(localStorage.getItem('dataSession')).data;

			const headers = new HttpHeaders({
				'Content-Type':  'application/json',
				'Authorization': `Bearer ${userData ? userData['token'] : ''}`,
			})

			this.http.post(this.dominio + servicio, data_a_enviar, {headers:headers})
			.subscribe(
				data => {
					this._temp_post = data;
					callback();
					this._temp_post = null;
				},
				(error) => {

					if(err) err();

					this.alerta.custom(
            'SAD_Emoji.svg',
            'No hemos podido conectarnos con Pullman Tur',
            '<i class="fas fa-arrow-left"></i> Intenta de nuevo',
            'error'
          )
				}
			)
		}

}
