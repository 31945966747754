import { Component, OnInit } from '@angular/core';

interface form_DATA {
  data:string,
  isValid:boolean,
  errorMsg:string
}

@Component({
  selector: 'app-recuperar-usuario',
  templateUrl: './recuperar-usuario.component.html',
  styleUrls: ['./recuperar-usuario.component.scss']
})
export class RecuperarUsuarioComponent implements OnInit {
  form:form_DATA[] = [
    { data:'', isValid:true, errorMsg:''},
    { data:'', isValid:true, errorMsg:''},
  ]

  constructor() { }

  ngOnInit() {
  }

  validarPassword() {
    if(!this.form[0].data) this.invalidar(this.form[0], "No puede estar vacío")
    else if(this.form[0].data.length < 8) this.invalidar(this.form[0], "Deben ser al menos 8 caracteres")
    else this.validar(this.form[0])

    this.confirmarPassword();
  }

  confirmarPassword() {
    if(this.form[1].data != this.form[0].data) this.invalidar(this.form[1], "La contraseña debe coincidir")
    else this.validar(this.form[1])
  }

  invalidar(input:form_DATA, error:string) {
    input.isValid = false;
    input.errorMsg = error;
  }

  validar(input:form_DATA) {
    input.isValid = true;
    input.errorMsg = '';
  }

  habilitarBoton() {
    if(this.form[0].data && this.form[1].data) {
      if(this.form[0].isValid && this.form[1].isValid) return true;
      else return false;
    }

    else return false;
  }

}
