import { Component, OnInit, ElementRef } from '@angular/core';
import { fadeWindow, fade } from '../../providers/animations';
import { LoginService } from '../login/login.service';
import { ComprarPasajeDataService } from 'src/app/comprar-pasaje/providers/comprar-pasaje-data.service';
import { WebServices } from 'src/app/providers/webServices';
import { IdSesionService } from 'src/app/providers/id-sesion.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
	
  animations: [
		fadeWindow,
		fade
  ]
})
export class HeaderComponent implements OnInit {
	
    mostrarUsuarioDialog:boolean = false;
  
    constructor(
  		public elementRef:ElementRef,
  		public login:LoginService,
  		public data:ComprarPasajeDataService,
  		public webServices:WebServices,
  	) { }
  
    ngOnInit() {
			this.login.verificarLoginAlEntrar;
    }

		cerrarDialogo(e:Event, elem:string) {
			if(e.target != this.elementRef.nativeElement.querySelector(`#${elem}`)) {
				this.login.mostrarLogin = false;
				this.mostrarUsuarioDialog = false;
			}
		}

		cerrarComponente(e:any) {
			this.mostrarUsuarioDialog = e.target;
		}
}
