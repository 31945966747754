import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { Component, OnInit, AfterViewChecked, ChangeDetectorRef } from '@angular/core';
import { Total } from  '../providers/tiposDatos';
import { ComprarPasajeDataService } from './providers/comprar-pasaje-data.service';
import { BuscarService } from '../core/busqueda-pasaje/buscar.service'
import { PasajeServiciosDataService } from './pasaje-servicios/pasaje-servicios-data.service';
import { ServiciosDataService } from './providers/servicios-data.service';
import Swal from 'sweetalert2'
import { BreadcrumbDataService } from './providers/breadcrumb-data.service';
import { WebServices } from '../providers/webServices';


@Component({
  selector: 'comprar-pasaje',
  templateUrl: './comprar-pasaje.component.html',
  styleUrls: ['./comprar-pasaje.component.scss'],
})
export class ComprarPasajeComponent implements OnInit, AfterViewChecked {

	tempInicializado:boolean = false;
	serviciosSeleccionadosConfirm = () => localStorage.getItem('serviciosSeleccionados') ? true : false;

	constructor(
		public router:Router,
		public data:ComprarPasajeDataService,
		public busquedaData:BuscarService,
		public serviciosData:ServiciosDataService,
		public bcData:BreadcrumbDataService,
		public changeDetectionRef:ChangeDetectorRef,
		public webServices:WebServices,
	) {

	}


 	ngOnInit() {
		document.getElementById('main-header').scrollIntoView({behavior:'smooth'});
  	}


	ngAfterViewChecked() {
		this.changeDetectionRef.detectChanges()
	}


	comprarPasajeTitulo = function() {
		switch (this.bcData.breadcrumbCurrentStep) {

			// case 1: return "Seleccione sus asientos"
			case 2: return "Coloque los datos del comprador"
			case 3: return "Verifique los datos de su compra"

			case 0:
				if(this.data.resultadosBusqueda.ida) {
					return "Elija su servicio"
				}

				else if(this.busquedaData.busquedaData.origen && !this.data.resultadosBusqueda.ida) {
					return "Buscando..."
				}

				else if(!this.busquedaData.busquedaData.origen) {
					return "Busca tu próximo destino"
				}

		}
	}

	irAPasoAnterior() {
		this.router.navigate([`comprar-pasaje/p${this.bcData.breadcrumbCurrentStep - 1}`]);
	}

}
