import { Injectable } from '@angular/core';
import { ComprarPasajeDataService } from '../providers/comprar-pasaje-data.service';

@Injectable({
  providedIn: 'root'
})
export class PasajeServiciosDataService {
	public enIda:boolean = true;
	public enIdaOcultar:boolean = false;

  constructor(
		public data:ComprarPasajeDataService
	) { }

	public cambiarIdaAVuelta() {
		this.enIda = !this.enIda;

		setTimeout(() => {
			this.enIdaOcultar = !this.enIdaOcultar;
		}, 1000)
	}
}
