import { Component, OnInit } from '@angular/core';
import { ComprarPasajeDataService } from '../providers/comprar-pasaje-data.service';
import { BreadcrumbDataService } from '../providers/breadcrumb-data.service';
import { Router } from '@angular/router';

@Component({
  selector: 'breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
	breadcrumbSteps:any[] = [ 'Pasajes', 'Asientos', 'Pago', 'Confirmación' ]

  constructor(
		public data:ComprarPasajeDataService,
		public bcData:BreadcrumbDataService,
		private router:Router,
	) { }

  ngOnInit() {
  }

  moverBusEnBreadcrumb(paso) {

		this.router.navigate([`comprar-pasaje/p${paso}`])
	  this.bcData.breadcrumbCurrentStep = paso;
		this.moverScrollbar();
	}

	actualizarPaso(event) {
		this.bcData.breadcrumbCurrentStep = event
	}

	moverScrollbar() {
		const ref = document.getElementById('comprar-pasaje-top');
		ref.scrollIntoView({behavior: 'smooth'});
	}

}
