import { Component, OnInit, ElementRef } from '@angular/core';
import { ComprarPasajeDataService } from '../providers/comprar-pasaje-data.service';
import { PasajeAsientosDataService } from './pasaje-asientos-data.service';
import { slideFade } from '../../providers/animations';
import { ServiciosDataService } from '../providers/servicios-data.service';
import Swal from 'sweetalert2';
import { BuscarService } from 'src/app/core/busqueda-pasaje/buscar.service';
import { BreadcrumbDataService } from '../providers/breadcrumb-data.service';
import { LoginService } from 'src/app/core/login/login.service';
import { WebServices } from 'src/app/providers/webServices';
import { Router } from '@angular/router';
import { AlertasService } from 'src/app/providers/alertas.service';
import { TimerService } from 'src/app/core/header/timer/timer.service';

type Viaje = 'ida'|'vuelta';

@Component({
  selector: 'pasaje-asientos',
  templateUrl: './pasaje-asientos.component.html',
  styleUrls: ['./pasaje-asientos.component.scss'],
	animations: [ slideFade ],
})
export class PasajeAsientosComponent implements OnInit {
	serviciosSeleccionados_local = localStorage.getItem('serviciosSeleccionados') ? JSON.parse(localStorage.getItem('serviciosSeleccionados')) : null;
	asientosSeleccionados_local = () => localStorage.getItem('asientosSeleccionados') ? JSON.parse(localStorage.getItem('asientosSeleccionados')).data : null;

	seat_map_DATA:any = {ida:[], vuelta:[]};
	seat_map_HTML:any = {ida:[], vuelta:[]};

	stops_selected:any = {
		ida: null,
		vuelta: null
	};
	stops_DATA:any = {
		ida:{ p:null, b:null },
		vuelta:{ p:null, b:null },
	};

	seat_reserved:any;

  constructor(
		public elementRef:ElementRef,
		public data:ComprarPasajeDataService,
		public asientosData:PasajeAsientosDataService,
		public serviciosData:ServiciosDataService,
		public busquedaData:BuscarService,
		public bcData:BreadcrumbDataService,
		public login:LoginService,
		public webServices:WebServices,
		public router:Router,
		public alerta:AlertasService,
		public timerService: TimerService,
	) { }

  ngOnInit() {
		this.bcData.breadcrumbCurrentStep = 1;
		this.instanciarAsientos();
		this.verificarAsientosSeleccionados();
  }

	seleccionDeAsiento(viaje:Viaje, seleccionado:number) {
		const asientosSeleccionados = this.data.asientosSeleccionados[viaje]
		const borrar:boolean = this.esSeleccionado(viaje, seleccionado).confirm;

		if(borrar) {
			this.cancelarReservaAsientos(viaje, seleccionado);
		}

		else {
			if(asientosSeleccionados.length < 6) {
				this.reservarAsientos(viaje, seleccionado);
			}

			else {
				// this.deseleccionar(viaje, seleccionado);
				this.uncheck(viaje, seleccionado);

				this.alerta.customWithMessage(
					'WARN_Icon.svg',
					'Ya seleccionaste 6 asientos',
					'Sólo puedes seleccionar hasta un máximo de seis (6) asientos por viaje de ida y un máximo de seis (6) asientos por viaje de regreso',
					'<i class="fas fa-arrow-left"></i> Intenta de nuevo',
					'error'
				)

			}
		}

	}

	instanciarAsientos() {
		this.cargarAsientos('ida');
		if(this.serviciosSeleccionados_local.vuelta) this.cargarAsientos('vuelta');
	}


	cargarAsientos(viaje:Viaje) {
		if(this.serviciosSeleccionados_local) {
			let idtramo = this.serviciosSeleccionados_local[viaje].idtramo;

			this.serviciosData.cargar(
				'seat_map',
				'ws_seat_map',
				`<id>${idtramo}</id>`,
				viaje,
				() => {
					this.seat_map_DATA[viaje] = this.serviciosData._temp_[0];

					const data_a_guardar = JSON.stringify({data:this.seat_map_DATA, hora: new Date().getTime()})

					localStorage.setItem('seat_map_DATA', data_a_guardar);

					this.cargarParadas(viaje);
					this.posicionarAsientos(viaje);
					this.confirmarCantidadAsientos(viaje);
					this.checkEnLista(viaje);
				}
			)
		}

		else{
      this.alerta.customWithMessage(
        'WARN_Icon.svg',
        'No hay servicios seleccionados',
        'Realiza una búsqueda en el formulario que se encuentra en la parte superior.',
        '<i class="fas fa-arrow-left"></i> Volver',
        'error',
        () => {
          this.router.navigate(['comprar-pasaje/p0'])
        }
      )
		}
	}


	cargarParadas(viaje:Viaje) {
		const busquedaData = JSON.parse(localStorage.getItem('busquedaData'))
		const paradas = ['b','p']

		for(let parada of paradas) {
			let params = '';
			if(viaje == 'vuelta'){
				params = `
				<origen>${busquedaData.destino}</origen>
				<destino>${busquedaData.origen}</destino>
				<tipo>${parada.toUpperCase()}</tipo>
				`
			}else{
				params = `
				<origen>${busquedaData.origen}</origen>
				<destino>${busquedaData.destino}</destino>
				<tipo>${parada.toUpperCase()}</tipo>
				`
			}


			this.serviciosData.cargar(
				'stops',
				'ws_stops',
				params,
				viaje,
				() => {
					this.stops_DATA[viaje][parada] = this.serviciosData._temp_;
				}
			)
		}

	}


	reservarAsientos(viaje:Viaje, seleccionado:number) {
		const idreserve = this.asientoNoSeleccionado(viaje, seleccionado).idreserve;
		let idtramo = this.serviciosSeleccionados_local[viaje]['idtramo'];
		const idSesion = JSON.parse(localStorage.getItem('idSesion')).id

		this.alerta.customLoading(
			'semicama-available_turned.svg',
			'Reservando asiento...'
		)

		this.serviciosData.cargar(
			'seat_reserve',
			'ws_seat_reserve',
			`<id>${idtramo}</id>
			<reserve>${idreserve}</reserve>
			<session_id>${idSesion}</session_id>`,
			viaje,

			(response) => {
				Swal.close();
				this.seleccionar(viaje, seleccionado, response['ticketid']);
			},

			() => {
				this.uncheck(viaje, seleccionado)
			}
		)
	}


	cancelarReservaAsientos(viaje:Viaje, seleccionado:number, automatico?:boolean) {

		if(!automatico){
			this.alerta.customLoading(
				'semicama-unavailable_turned.svg',
				'Cancelando reserva...'
			);
		}
		
		const idSesion = JSON.parse(localStorage.getItem('idSesion')).id

		this.serviciosData.cargar(
			'cancel_seat',
			'ws_cancel_seat',
			`<session_id>${idSesion}</session_id>`,
			viaje,
			() => {
				this.deseleccionar(viaje, seleccionado);
				
				this.verificarAsientosSeleccionados();
				if(!automatico)
					Swal.close();
			},
			() => {
				if(this.serviciosData.errorMensaje = "Asiento tomado o no hay reservas con esta session" || "Asiento tomado o no hay reservas con esta sesion") {
					this.deseleccionar(viaje, seleccionado);
					this.verificarAsientosSeleccionados();
					if(!automatico)
						Swal.close();
				}

				else this.check(viaje, seleccionado);
			}
		)
	}

	cancelarReservasLote(reservas){
		this.timerService.finalizarTemporizador();
		
		if(reservas['ida']){
			for(let asiendo of (reservas['ida'])){
				this.deseleccionar('ida', asiendo.seat);
				this.cancelarReservaAsientos('ida',asiendo.seat, true);
			}
		}

		if(reservas['vuelta']){
			for(let asiendo of (reservas['vuelta'])){
				this.deseleccionar('vuelta', asiendo.seat);
				this.cancelarReservaAsientos('vuelta',asiendo.seat, true);
			}
		}
		this.data.asientosSeleccionados = {
			ida:[],
			vuelta:[],
			idtramo:{ida:null, vuelta:null}
		};
		localStorage.removeItem('asientosSeleccionados');
		this.timerService.terminarCompra();

}

	confirmarCantidadAsientos(viaje:Viaje) {
		const confirmed:number = this.seat_map_DATA[viaje].filter(asiento => asiento.available).length;

		if(confirmed == 0) {
      this.alerta.customWithMessage(
        'WARN_Icon.svg',
		`No hay asientos disponibles en el viaje de ${viaje}`,
        'Intenta nuevamente con otro servicio',
        '<i class="fas fa-arrow-left"></i> Intenta de nuevo',
        'error',
      )
		}

	}

  htmlData = (viaje, asiento) => `
    <input id="bus-${viaje}-chk-${asiento.seat}" class="bus-seat-chk" type="checkbox">
    <label class="bus-${this.tipoDeAsiento_toClass(asiento.typeseat)}" for="bus-${viaje}-chk-${asiento.seat}">
      <span>${asiento.seat}</span>
    </label>`

	posicionarAsientos(viaje:Viaje) {
		const table = this.elementRef.nativeElement.querySelectorAll(`.bus-${viaje}-col`);
		const asientos = this.seat_map_DATA[viaje];
    const servicioSeleccionado = JSON.parse(localStorage.getItem('serviciosSeleccionados'))[viaje];

		table.forEach(col => {
			asientos.forEach(asiento => {


				if(asiento.seat < 60 && asiento.typeseat == this.tipoDeAsiento_number(servicioSeleccionado.tipo)) {

					if(asiento.cell == col.dataset.cell && asiento.order == col.dataset.order && asiento.floor == col.dataset.floor) {
						col.innerHTML = this.htmlData(viaje, asiento);
						this.seat_map_HTML[viaje].push(this.htmlData(viaje, asiento));
            this.definirEventoAsiento(viaje, asiento);
					}

          else if(asiento.seat == 59 || (asiento.seat == 3 && asiento.typeseat == 5 )){
            this.elementRef.nativeElement.querySelector(`#bus-${viaje}-59`).innerHTML = this.htmlData(viaje, asiento)
            this.definirEventoAsiento(viaje, asiento);
          }
				}
			})
    })

	}


  definirEventoAsiento(viaje:Viaje, asiento:any){
    const chk = this.elementRef.nativeElement.querySelector(`#bus-${viaje}-chk-${asiento.seat}`);

    if(this.esSeleccionado(viaje, asiento.seat).confirm) {
      this.check(viaje, asiento.seat);
      this.asignarEvento(chk, viaje, asiento.seat);
    }

    else {
      if(asiento.available == false){
        chk.disabled = true;
      }

      else this.asignarEvento(chk, viaje, asiento.seat);
    }
  }


	asignarEvento(checkbox:HTMLElement, viaje:Viaje, seleccionado:number) {
		checkbox.addEventListener('change', () => {
			this.seleccionDeAsiento(viaje, seleccionado);
		});
	}


  tipoDeAsiento_toClass = function(numero:number) {
		switch (numero) {
			case 2:
				return "SemiCama"
			case 4:
				return "SalonCamaVIP"
			case 5:
				return "SalonCama"
		}
	}

  tipoDeAsiento_number = function(texto:string) {
		switch (texto) {
			case "Semi Cama":
				return 2
			case "Cama VIP":
				return 4
			case "Cama":
				return 5
		}
	}


	deseleccionar(viaje:Viaje, seleccionado:number) {
		this.uncheck(viaje, seleccionado);

		if (this.esSeleccionado(viaje, seleccionado).index != null) {
			this.data.asientosSeleccionados[viaje].splice(this.esSeleccionado(viaje, seleccionado).index, 1);
		} 

		const data = JSON.stringify({data: this.data.asientosSeleccionados, hora: this.login.hora});
		localStorage.setItem('asientosSeleccionados', data)
	}


	seleccionar(viaje:Viaje, seleccionado:number, ticketid?:string) {
		const asiento = this.asientoNoSeleccionado(viaje, seleccionado);
		this.check(viaje, seleccionado);
		
		if(ticketid) {
			asiento['ticketid'] = ticketid;
		}

		this.data.asientosSeleccionados[viaje].push(asiento);

		const data = JSON.stringify({data: this.data.asientosSeleccionados, hora: this.login.hora});
		localStorage.setItem('asientosSeleccionados', data)

	}

	
	setParadas(){
		localStorage.setItem('paradas', JSON.stringify(this.stops_selected));
	}


	esSeleccionado = function(viaje:Viaje, seleccionado:number):any {
		let selec:any = {confirm:false, index:null};

		if(localStorage.getItem('asientosSeleccionados')) {
			const asientosSeleccionados = JSON.parse(localStorage.getItem('asientosSeleccionados')).data[viaje]

			let i = 0;

			for(let asiento of asientosSeleccionados) {
				if(asiento.seat == seleccionado) {
					selec.confirm = true;
					selec.index = i;

					break;
				}

				i++
			}
		}

		return selec;
	}


	asientoNoSeleccionado = function(viaje:Viaje, seleccionado:number) {
		const seat_map = this.seat_map_DATA[viaje];
		return seat_map.filter(asiento => asiento.seat == seleccionado)[0]
	}


	isChecked = function(viaje:Viaje, seleccionado:number):boolean {
		return this.elementRef.nativeElement.querySelector(`#bus-${viaje}-chk-${seleccionado}`).checked
	}

	check = function(viaje:Viaje, seleccionado:number) {
		this.elementRef.nativeElement.querySelector(`#bus-${viaje}-chk-${seleccionado}`).checked = true
	}

	uncheck = function(viaje:Viaje, seleccionado:number) {
		this.elementRef.nativeElement.querySelector(`#bus-${viaje}-chk-${seleccionado}`).checked = false
	}

	enable = function(viaje:Viaje, seleccionado:number) {
		this.elementRef.nativeElement.querySelector(`#bus-${viaje}-chk-${seleccionado}`).disabled = false
	}

	disable = function(viaje:Viaje, seleccionado:number) {
		this.elementRef.nativeElement.querySelector(`#bus-${viaje}-chk-${seleccionado}`).disabled = true
	}

	checkEnLista(viaje:Viaje) {
		// if(localStorage.getItem('asientosSeleccionados')) {
		// 	const asientosSeleccionados = JSON.parse(localStorage.getItem('asientosSeleccionados')).data[viaje]
		//
		// 	if(asientosSeleccionados.ida) {
		// 		this.seleccionarDesdeLista(viaje)
		// 	}
		//
		// 	else localStorage.removeItem('asientosSeleccionados')
		//
		// }
	}


	seleccionarDesdeLista(viaje:Viaje) {
		const asientosSeleccionados = this.asientosSeleccionados_local();

		if(asientosSeleccionados.idtramo[viaje]){
			const servicioSeleccionado = this.serviciosSeleccionados_local[viaje]

			if(servicioSeleccionado.idtramo == asientosSeleccionados.idtramo[viaje]) {

				for(let asiento of asientosSeleccionados[viaje]) {
					this.enable(viaje, asiento.seat)
					this.seleccionar(viaje, asiento.seat);
				}

			}
		}
	}


	irAPasoAnterior(){
		// const asientosSeleccionados_local() = localStorage.getItem('asientosSeleccionados') ? JSON.parse(localStorage.getItem('asientosSeleccionados')).data : null;
		//
		// if(asientosSeleccionados_local()) {
		//
		// 	this.cancelarReservaEnLote(asientosSeleccionados_local().ida);
		// 	if(asientosSeleccionados_local().vuelta) this.cancelarReservaEnLote(asientosSeleccionados_local().vuelta);
		//
		//
		// 	const i_router = setInterval(() => {
		// 		const data = JSON.parse(localStorage.getItem('asientosSeleccionados')).data
		//
		// 		if(!data.ida.length) {
		// 			localStorage.removeItem('asientosSeleccionados');
		// 			this.router.navigate(['comprar-pasaje/p0']);
		// 			clearInterval(i_router);
		// 		}
		//
		// 		if(!data.ida.length && !data.vuelta.length) {
		// 			localStorage.removeItem('asientosSeleccionados');
		// 			this.router.navigate(['comprar-pasaje/p0']);
		// 			clearInterval(i_router);
		// 		}
		// 	}, 200)
		// }
		//
		// else this.router.navigate(['comprar-pasaje/p0']);

	}


	verificarAsientosSeleccionados() {
		if (this.asientosSeleccionados_local()) {
			if (!this.asientosSeleccionados_local().ida.length && !this.asientosSeleccionados_local().vuelta.length) {
				localStorage.removeItem('asientosSeleccionados');
				this.timerService.finalizarTemporizador();
			}
		}
	}

}
