import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ServiciosDataService } from 'src/app/comprar-pasaje/providers/servicios-data.service';
import { AlertasService } from './../../providers/alertas.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { WebServices } from 'src/app/providers/webServices';
import { AccesoCliente } from 'src/app/providers/tiposDatos';
import { ComprarPasajeDataService } from 'src/app/comprar-pasaje/providers/comprar-pasaje-data.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

	mostrarLogin:boolean = false;
	cerrarLogin:boolean = false;
	iniciandoSession:boolean = false;
	cerrandoSession:boolean = false;
	sessionIniciada:boolean = false;

	login_data:AccesoCliente = {
	    idDocument:'',
	    password:'',
	    activo:null
	}
	public formLogin: FormGroup = this.formBuilder.group({
		idDocument: ['',Validators.compose([
			Validators.required, 
			Validators.pattern("[0-9]+"),
			Validators.minLength(7),
			Validators.maxLength(8)
	])],
		password: ['', Validators.compose([Validators.required])]
	});

	constructor(
		private http: HttpClient,
		private webServices: WebServices,
		private alerta: AlertasService,
		private data:ComprarPasajeDataService,
		private router:Router,
		private serviciosData: ServiciosDataService,
		public formBuilder: FormBuilder
	) { }

	

  verificarDatos(usuario){
		this.iniciandoSession = true;
		if(localStorage.getItem('dataSession')) localStorage.removeItem('dataSession');

		let params = `<rutcliente>${usuario.idDocument}</rutcliente>
    	<password>${usuario.password}</password>`;


		this.serviciosData.cargar('login',
		'ws_login', params,'ida',
		()=>{
			this.iniciandoSession = false;
			const data = JSON.stringify({data:this.serviciosData._temp_, hora: new Date().getTime()});

			if(this.serviciosData._temp_){
				this.sessionIniciada = true;
				localStorage.setItem('dataSession', data);
				this.verificarJWT(()=>{ this.llenarInformacionComprador() })
				this.cerrarComponente();
			}
		
		},
		()=>{
			this.iniciandoSession = false;
		})
  }


	verificarJWT(callback:()=> void) {
		const i_token = setInterval(() => {
			const data = localStorage.getItem('dataSession') ? JSON.parse(localStorage.getItem('dataSession')) : null;

			if(data) {
				clearInterval(i_token);
				callback();
			}
		}, 300)
	}


	readonly nombreUsuario = () => {
		const data = (localStorage.getItem('dataSession') != null) ? JSON.parse(localStorage.getItem('dataSession')).data[0] : null;
		return data.nombre;
	}

	readonly nombreCompleto = () => {
		const data = localStorage.getItem('dataSession') ? JSON.parse(localStorage.getItem('dataSession')).data[0] : null;
		return data.nombre;
	}


	readonly verificarLoginAlEntrar = this.verificarDataAlEntrar('dataSession',
		()=>{
			this.sessionIniciada = true;
			this.llenarInformacionComprador();
		},
	)


	verificarDataAlEntrar(key:string, callback:() => void, error?:() => void) {
		const data = localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : null;

		if(data ) {
			const hora = data['hora'];

			if(this.autoInitSession(hora)){
				callback();
			}

			else {
				localStorage.removeItem(key);
				if(error) error()
			}

		}
	}


	autoInitSession(horaPasada){
		let horaActual = new Date().getTime();
		if(horaActual < (horaPasada + 2000000)) return true
		else return false;
	}


	cerrarSesion() {
		this.cerrandoSession = true;
	
		this.sessionIniciada = false;
		this.cerrandoSession = false;
		localStorage.removeItem('dataSession');
		this.alerta.custom(
			'SMILE_Emoji.svg',
			'¡Gracias por su visita!',
			'Continuar <i class="fas fa-arrow-right"></i>',
			'success'
		)

		this.router.navigate(['/']);

	}

	cerrarComponente(){
		this.mostrarLogin = false;
	}

	llenarInformacionComprador (){
		let deailUser = JSON.parse(localStorage.getItem('dataSession'))['data'][0];
		this.data.datosDelCliente.controls['name'].setValue(deailUser.nombre);
		this.data.datosDelCliente.controls['idDocument'].setValue(deailUser.rutcliente);
		this.data.datosDelCliente.controls['phone'].setValue(deailUser.telefono);
		this.data.datosDelCliente.controls['email'].setValue(deailUser.email);
		this.data.emailConfirmControl = deailUser.email;
	}

	hora = new Date().getTime();

}
