import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import xml2js from 'xml2js';

@Injectable({
  providedIn: 'root'
})
export class ListaCiudadesService {

	public lista:any;

	public headers = new HttpHeaders({
		'Content-Type' : 'text/xml',
		'Access-Control-Allow-Methods': 'POST, GET, DELETE, PUT, OPTIONS',
		'Access-Control-Allow-Origin':'busespullmantur.cl',
		'Access-control-Allow-Headers':'Content-Type, x-requested-with, Authorization, Access-Control-Allow-Origin',
	});

    constructor(public http:HttpClient) {
		// this.cargarCiudades();
	}

	public cargarCiudades() {
		let url = "https://ptur-ws-web.azurewebsites.net/ws_external/api/pullmantur/cities.php?WSDL";

		let input:string =`
		<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/">
	    <soapenv:Body>
		    <ws_cities>
			    <api>PULLM4N_TURCL</api>
		    </ws_cities>
	    </soapenv:Body>
   </soapenv:Envelope>
	 `

		this.http.post( url, input, { headers: this.headers, responseType:'text' })
		.subscribe(
			data => {
				localStorage.setItem('_lista_ciudades_', this.xmlToJSON(data)['SOAP-ENV:Envelope']['SOAP-ENV:Body']['ns1:ws_citiesResponse']['return']['_'])
				this.lista = JSON.parse(localStorage.getItem('_lista_ciudades_'))
			},

			(error:HttpErrorResponse) => {

			}
		)

	}

	public mostrarCiudades() {
		let ciudadesGuardadas = localStorage.getItem('_lista_ciudades_');

		if(ciudadesGuardadas) {
			this.lista = JSON.parse(ciudadesGuardadas);
		}

		else {
			this.cargarCiudades()
		}
	}

	public xmlToJSON(data) {
		let stringJson

    xml2js.parseString(data, {explicitArray:false}, (error, result)=> {
			stringJson = result
    });
		return stringJson;
  }
}
