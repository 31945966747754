import { Component, OnInit, ElementRef, EventEmitter, Output, Input } from '@angular/core';
import { ListaCiudadesService } from '../ciudades/lista-ciudades.service';

@Component({
  selector: 'lista-ciudades',
  templateUrl: './ciudades.component.html',
  styleUrls: ['./ciudades.component.scss'],
})
export class CiudadesComponent implements OnInit {

	@Input() tipo:string;
	@Output() ciudadSeleccionada = new EventEmitter;

	public buscador_input:string = ''

  constructor(
		public ciudades : ListaCiudadesService,
		public elementRef : ElementRef,
	) { }

  ngOnInit() {
	//   this.ciudades.cargarCiudades();
		// console.log(this.listaFiltrada())
  }

	public listaFiltrada() {
		let ciudades:any[] = this.ciudades.lista['resultado'][0]['response'];
		let filtradas:any[] = [];
		filtradas.length = 0;

		for(let ciudad of ciudades) {
			if(ciudad.ciudad.match(this.buscador_input.toUpperCase()) || ciudad.ciudad.match(this.buscador_input.toLowerCase())) {
				filtradas.push(ciudad)
			}
		}

		return filtradas
	}

	public filtrarCiudades(ciudad:any) {
		if(ciudad.ciudad.match(this.buscador_input)) return ciudad
	}

	public seleccionarCiudad(ciudad) {
		this.ciudadSeleccionada.emit(ciudad)
	}

}
