import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ComprarPasajeDataService } from 'src/app/comprar-pasaje/providers/comprar-pasaje-data.service';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class TimerService {
  storedTime = () => localStorage.getItem('compra_timer') 
    ? JSON.parse(localStorage.getItem('compra_timer')) 
    : null;

  segundos:number;
  minutos:number;
  segReset:number = 60;
  segundosTotales:number;

  initCompra:boolean = false;
  
  i_segundos;

  constructor(
    public data: ComprarPasajeDataService,
    public router: Router,
  ) {
  }


  initTemporizador() {
    if(this.storedTime()) {
      this.segundosTotales = this.storedTime().data.segundosTotales;
      this.segundos = this.storedTime().data.segundos;
      this.minutos = this.storedTime().data.minutos;
    }
    
    else{
      this.rebootTimerData();
    }
    
    // this.compraReset();
    this.temporizador()
  }


  finalizarTemporizador() {
    clearInterval(this.i_segundos);
    localStorage.removeItem('compra_timer');
    this.rebootTimerData();
  }


  compraReset() {
    this.data.compraDataReset();
    this.data.compraConfirm = false;
  }


  temporizador() {
    this.i_segundos = setInterval(() => {
      if (!this.segundos) {
        this.segundos = this.segReset;
        this.minutos--;
      }

      this.segundosTotales++;
      this.segundos--;
      localStorage.setItem('compra_timer', `{"data": {"segundos": ${this.segundos}, "minutos": ${this.minutos}, "segundosTotales": ${this.segundosTotales}}, "hora": ${new Date().getTime()}}`);

    }, 1000)
  }


  rebootTimerData() {
    this.segundosTotales = 0;
    this.segundos = 0;
    this.minutos = 10;
  }


  terminarCompra() {
    if (!this.data.compraConfirm) {
      this.mensajeTimeup();
      console.warn('Proceso de compra terminado');
    }
  }


  mensajeTimeup = () => {
    Swal({
      title: 'Tiempo agotado',
      text: 'Se ha terminado el tiempo máximo para realizar esta compra, por favor vuelve a realizar otra búsqueda para repetir el proceso.',
      type: 'error',
      confirmButtonText: 'Realizar nueva búsqueda',
      allowOutsideClick: false,
      allowEscapeKey: false,
    }).then((result) => {
      if (result.value) {
        this.router.navigate(['/comprar-pasaje'])
      }
    })
  }
}
