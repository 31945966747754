import { Injectable } from '@angular/core';
import Swal, { SweetAlertType } from 'sweetalert2';
@Injectable({
  providedIn: 'root'
})
export class AlertasService {

  constructor() { }

  deExito(titulo:string, texto?:string) {
    Swal({
      title: titulo,
      type: 'success',
      text: texto,
      confirmButtonText: 'Ok'
    });
  }

  deEspera(titulo:string) {
    Swal({
			title: titulo,
			showConfirmButton: false,
			allowOutsideClick: false,
			allowEscapeKey:false
    });
  }

  deError(titulo:string, texto?:string) {
    Swal({
      title: titulo,
      type: 'error',
      text: texto,
    })
  }

  cerrar(){
    Swal.close();
  }

  customLoading(imagen:string, texto:string) {
    Swal({
      html: `
        <div class="custom-alert-pic custom-loading-pic">
          <div class="spinner"></div>
          <img src="assets/img/${imagen}" />
        </div>

        <h4>${texto}</h4>
      `,
      customClass:`custom-alert custom-loading`,
			showConfirmButton: false,
			allowOutsideClick: false,
			allowEscapeKey: false,
		})
  }

  custom(imagen:string, texto:string, buttonText:string, tipo:SweetAlertType, callback?:() => void) {
    Swal({
      html: `
        <div class="custom-alert-pic">
          <img src="assets/img/${imagen}" />
        </div>

        <h4>${texto}</h4>
      `,
      customClass:`custom-alert custom-alert-${tipo}`,
      confirmButtonText: buttonText,
			allowOutsideClick: true,
			allowEscapeKey: true,
    }).then(() => { if(callback)callback })
  }

  customWithMessage(imagen:string, texto:string, message:string, buttonText:string, tipo:SweetAlertType, callback?:()=>void) {
    Swal({
      html: `
        <div class="custom-alert-pic">
          <img src="assets/img/${imagen}" />
        </div>

        <h4>${texto}</h4>

        <p>${message}</p>
      `,
      customClass:`custom-alert custom-alert-${tipo} custom-alert-with-message`,
      confirmButtonText: buttonText,
			allowOutsideClick: true,
			allowEscapeKey: true,
		}).then(() => { if(callback)callback })
  }


}
