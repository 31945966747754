import { Component, OnInit } from '@angular/core';
import { ComprarPasajeDataService } from '../providers/comprar-pasaje-data.service'
import { BuscarService } from '../../core/busqueda-pasaje/buscar.service'

declare global {
	interface Window { Screen:any }
}

@Component({
  selector: 'pasaje-info',
  templateUrl: './pasaje-info.component.html',
  styleUrls: ['./pasaje-info.component.scss']
})
export class PasajeInfoComponent implements OnInit {

	mostrarInfo_Ida:boolean = true
	mostrarInfo_Vuelta:boolean = true

  constructor(
		public data : ComprarPasajeDataService,
		public busquedaData : BuscarService,
	) { }

  ngOnInit() {
		this.forSmallScreens()
  }

	public forSmallScreens() {
		if(screen.width <= 992) {
			this.mostrarInfo_Ida = false;
			this.mostrarInfo_Vuelta = false;
		}
	}


}
