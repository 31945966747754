import { Component, OnInit } from '@angular/core';
import { ComprarPasajeDataService } from '../providers/comprar-pasaje-data.service'
import { slideFade } from '../../providers/animations'
import { BreadcrumbDataService } from '../providers/breadcrumb-data.service';
import { BuscarService } from 'src/app/core/busqueda-pasaje/buscar.service';

@Component({
  selector: 'pasaje-confirm',
  templateUrl: './pasaje-confirm.component.html',
  styleUrls: ['./pasaje-confirm.component.scss'],
	animations: [ slideFade ],
})
export class PasajeConfirmComponent implements OnInit {
	serviciosSeleccionados = JSON.parse(localStorage.getItem('serviciosSeleccionados'));
	asientosSeleccionados = JSON.parse(localStorage.getItem('asientosSeleccionados')).data;

	paraderoSeleccionados = {ida: "Ninguno", vuelta: "Ninguno"};

	constructor(
		public data : ComprarPasajeDataService,
		public bcData:BreadcrumbDataService,
		public busquedaData:BuscarService,
	) { }

  ngOnInit() {
		this.bcData.breadcrumbCurrentStep = 3;
		this.setParaderos();

  }

  setParaderos() {
	  if (localStorage.getItem('paradas')) {
		  const paradasStorage = JSON.parse(localStorage.getItem('paradas'));

		  for (let viaje in paradasStorage) {
			  if (paradasStorage[viaje]) {
				  this.paraderoSeleccionados[viaje] = paradasStorage[viaje];
			  }
		  }
	}
  }

}
