import { NgModule } from '@angular/core';
import { WebServices } from './providers/webServices';
import { FormatoFechaService } from './providers/formatoFecha.service';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ClickOutsideModule } from 'ng-click-outside';

import { NgbDatepickerModule, NgbModalModule } from '@ng-bootstrap/ng-bootstrap'

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { HeaderComponent } from './core/header/header.component';
import { FooterComponent } from './core/footer/footer.component';
import { ComprarPasajeComponent } from './comprar-pasaje/comprar-pasaje.component';
import { BusquedaPasajeComponent } from './core/busqueda-pasaje/busqueda-pasaje.component';
import { PasajeServiciosComponent } from './comprar-pasaje/pasaje-servicios/pasaje-servicios.component';
import { PasajeAsientosComponent } from './comprar-pasaje/pasaje-asientos/pasaje-asientos.component';
import { PasajePagoComponent } from './comprar-pasaje/pasaje-pago/pasaje-pago.component';
import { PasajeConfirmComponent } from './comprar-pasaje/pasaje-confirm/pasaje-confirm.component';
import { RegistroComponent } from './usuario/registro/registro.component';
import { PerfilComponent } from './usuario/perfil/perfil.component';
import { HttpClientModule } from '@angular/common/http';
import { HttpModule } from '@angular/http';


import { CuadroTotalComponent } from './comprar-pasaje/cuadro-total/cuadro-total.component';
import { CiudadesComponent } from './core/ciudades/ciudades.component';
import { PasajeInfoComponent } from './comprar-pasaje/pasaje-info/pasaje-info.component';
import { LoginComponent } from './core/login/login.component';
import { PasajeServiciosIdaVueltaComponent } from './comprar-pasaje/pasaje-servicios-ida-vuelta/pasaje-servicios-ida-vuelta.component';
import { BreadcrumbComponent } from './comprar-pasaje/breadcrumb/breadcrumb.component';
import { TimerComponent } from './core/header/timer/timer.component';
import { DialogComponent } from './usuario/dialog/dialog.component';
import { RecuperarUsuarioComponent } from './usuario/recuperar-usuario/recuperar-usuario.component';
import {MatTableModule} from '@angular/material/table'; 
import { MatPaginatorModule } from '@angular/material';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    ComprarPasajeComponent,
    BusquedaPasajeComponent,
    PasajeServiciosComponent,
    PasajeAsientosComponent,
    PasajePagoComponent,
    PasajeConfirmComponent,
    RegistroComponent,
    PerfilComponent,
    CuadroTotalComponent,
    CiudadesComponent,
    PasajeInfoComponent,
    LoginComponent,
    PasajeServiciosIdaVueltaComponent,
    BreadcrumbComponent,
    TimerComponent,
    DialogComponent,
    RecuperarUsuarioComponent,
  ],
  imports: [
    BrowserModule,
		BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    HttpModule,
    ReactiveFormsModule,
		NgbDatepickerModule,
		NgbModalModule,
    ClickOutsideModule,
    MatTableModule,
    MatPaginatorModule
  ],
  providers: [
	],
  bootstrap: [AppComponent]
})
export class AppModule { }
