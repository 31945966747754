import { Component, OnInit, ViewChild } from '@angular/core';
import { ComprarPasajeDataService } from '../providers/comprar-pasaje-data.service';
import { RealizarCompraService } from '../providers/realizar-compra.service';
import { Cliente } from '../../providers/tiposDatos'
import { slideFade } from '../../providers/animations'
import { BreadcrumbDataService } from '../providers/breadcrumb-data.service';
import { LoginService } from 'src/app/core/login/login.service';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'pasaje-pago',
  templateUrl: './pasaje-pago.component.html',
  styleUrls: ['./pasaje-pago.component.scss'],
	animations: [ slideFade ],
})
export class PasajePagoComponent implements OnInit {

	public mensajeError : string = '';
	public verificarValidacionConfirm : boolean = false

	usuarioData:string;

	// RegExp patrones
	public texto = /^[A-Za-z\s?]+$/
	public numero = /^[0-9]+$/
	public email = /[A-Za-z_\.+]+@[A-Za-z]+\.[A-Za-z]+/
	// email = /^[\D\d\.]+$@^[a-z\.]+$\.\^[a-z]+$/

  constructor(
		public data : ComprarPasajeDataService,
		public realizarCompra : RealizarCompraService,
		public bcData:BreadcrumbDataService,
		public login:LoginService,

	) { }

  ngOnInit() {
		this.bcData.breadcrumbCurrentStep = 2;
		if(!this.login.sessionIniciada) this.data.datosDelClienteReset()
  }

	public validar(index, regExp) {
		let campo = this.data.datosDelCliente[index]

		if(campo.data) {
			if(campo.data.match(regExp)) {
				if(campo.data.length < campo.minLength){
					campo.isValid = false;
					campo.errorMsg = `Deben ser por lo menor ${campo.minLength} caracteres`;
				}

				else {
					campo.isValid = true;
					campo.errorMsg = ""
				}
			}

			else {
				campo.isValid = false

				if (regExp == this.texto) campo.errorMsg = "Sólo se permiten letras"
				else if (regExp == this.numero) campo.errorMsg = "Sólo se permiten números"
				else if (regExp == this.email) campo.errorMsg = "Coloca un email válido"
			}
		}

		else {
			campo.isValid = false
			campo.errorMsg = "No puede estar vacío"
		}

	
	}


	public validarConfirmarEmail() {
		const campo = this.data.datosDelCliente[5];

		if(campo.data) {
			if(campo.data == this.data.datosDelCliente[4].data) {
				campo.isValid = true;
				campo.errorMsg = "";
			}

			else {
				campo.isValid = false;
				campo.errorMsg = "El email debe coincidir";
			}
		}

		else {
			campo.isValid = false;
			campo.errorMsg = "No puede estar vacío";
		}

	}


	verificarValidacion(index, regExp) {
		this.verificarValidacionConfirm = false;

		setTimeout(() => {
			this.verificarValidacionConfirm = true;

			if (this.verificarValidacionConfirm) {
        if(index < 6) this.validar(index, regExp);
        else this.validarConfirmarEmail();
      }

		}, 1500)

	}

}
