import { LoginService } from './../../core/login/login.service';
import { ServiciosDataService } from './../../comprar-pasaje/providers/servicios-data.service';
import { AlertasService } from './../../providers/alertas.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { WebServices } from 'src/app/providers/webServices';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class RegistroService {

  constructor(
    private http: HttpClient,
    private url: WebServices,
    private serviciosData: ServiciosDataService,
    private alerta: AlertasService,
    public login: LoginService
  ) {
    
  
   }


  async usuarioNuevo(datos)
  {
    await this.alerta.customLoading('PT_ISOTIPO.svg','Registrando datos...')

    let params = `<rutcliente>${datos.idDocument}</rutcliente>
    <password>${datos.password}</password>
    <nombre>${datos.name}</nombre>
    <apellido>${datos.lastname}</apellido>
    <telefono>${datos.phone}</telefono>
    <email>${datos.email}</email>`;

    await this.serviciosData.cargar('register',
    'ws_register', params, 'ida', 
    () =>{
      this.alerta.cerrar();
      this.alerta.deExito('Registo exitoso');

      this.login.verificarDatos({idDocument:datos.idDocument, password: datos.password});
    }, ()=>{
      this.alerta.cerrar();
      this.alerta.deError("Error",this.serviciosData.errorMensaje);
    });
  }

}

