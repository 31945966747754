import { Injectable } from '@angular/core';
import { Viaje, Asiento, Servicio, Cliente } from '../../providers/tiposDatos';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { EmailValidation } from 'src/app/providers/EmailValidation';

@Injectable({
  providedIn: 'root'
})
export class ComprarPasajeDataService {
  resultadosBusquedaStorage = () => localStorage.getItem('resultadosBusqueda') ? JSON.parse(localStorage.getItem('resultadosBusqueda')).data : null;
  asientosSeleccionadosStorage = () => localStorage.getItem('asientosSeleccionados') ? JSON.parse(localStorage.getItem('asientosSeleccionados')).data : null;
  serviciosSeleccionadosStorage = () => localStorage.getItem('serviciosSeleccionados') ? JSON.parse(localStorage.getItem('serviciosSeleccionados')) : null;
  busquedaDataStorage = () => localStorage.getItem('busquedaData') ? JSON.parse(localStorage.getItem('busquedaData')) : null;
  idSesionStorage = () => localStorage.getItem('idSesion') ? JSON.parse(localStorage.getItem('idSesion')).id : null;

	initCompra:boolean = false;
	compraConfirm:boolean = false;

  resultadosBusqueda:any = { ida:null, vuelta:null };
	origen:Viaje;
	destino:Viaje;

	servicio_Ida:any;
	servicio_Vuelta:any;

	servSeleccionado_Ida:any;
	servSeleccionado_Vuelta:any;

	serviciosSeleccionados:any = {ida:null, vuelta:null}

	emailConfirmControl = "";

	asientosSeleccionados:any = {
		ida:[],
		vuelta:[],
		idtramo:{ida:null, vuelta:null}
	};


	datosDelCliente = this.formBuilder.group({
		name: ['', Validators.compose([Validators.required, Validators.pattern('[a-zA-Z ]*')])],
		idDocument: ['', Validators.compose([Validators.required, Validators.minLength(7), Validators.maxLength(8), Validators.pattern("[0-9]+")])],
		phone: ['', Validators.compose([Validators.required, Validators.minLength(9), Validators.pattern("[0-9]+")])],
		email: ['', Validators.compose([Validators.required, Validators.email])],
	});

	datosDelClienteArray = () => {
		return [
			this.datosDelCliente.controls.name.value,
			this.datosDelCliente.controls.idDocument.value,
			this.datosDelCliente.controls.phone.value,
			this.datosDelCliente.controls.email.value,
			this.emailConfirmControl
		]
	}

	validateEmailConfirmControl() {
		if (this.emailConfirmControl == this.datosDelCliente.controls.email.value) {
			return true;
		} else {
			return false;
		}
	}

	
  tipopago = 'webpay';


  constructor(
	  public router:Router,
	  private formBuilder: FormBuilder,
	) {
	}


	precioPorServicio = function (viaje:'ida'|'vuelta'):number{
		let precio:number= 0;
		if(this.serviciosSeleccionadosStorage())
		precio = parseInt(this.serviciosSeleccionadosStorage()[viaje]['precio']);
		
		return precio;
	}


	totalPorViaje = function(viaje:'ida'|'vuelta'):number {
		let suma:number = 0;

		if(localStorage.getItem('asientosSeleccionados')) {
			const asientosSeleccionados = JSON.parse(localStorage.getItem('asientosSeleccionados')).data[viaje];

			for(let asiento of asientosSeleccionados) {
				suma += parseInt(asiento.price)
			}
		}

		return suma;
	}


	totalCompra = function() : number {
		return this.totalPorViaje('ida') + this.totalPorViaje('vuelta');
	}

	
	compraDataReset() {
		this.serviciosSeleccionados = {ida:null, vuelta:null};
		localStorage.removeItem('serviciosSeleccionados');

		this.asientosSeleccionados = {ida:[], vuelta:[], idtramo:{ida:null, vuelta:null}};
		localStorage.removeItem('asientosSeleccionados');

		let i = 0;
	}


	datosDelClienteReset() {
		this.datosDelCliente.controls['name'].setValue("");
		this.datosDelCliente.controls['idDocument'].setValue("");
		this.datosDelCliente.controls['phone'].setValue("");
		this.datosDelCliente.controls['email'].setValue("");
		this.emailConfirmControl = "";
		this.datosDelCliente.reset();
	}


	confirmarAsientosSeleccionados = () => {
		if(localStorage.getItem('asientosSeleccionados')) {
			const asientos = JSON.parse(localStorage.getItem('asientosSeleccionados')).data;
		}
	}


  viajeData():any[] {
    let id_list = [];
    let ida = this.asientosSeleccionadosStorage()['ida'];
	let vuelta = this.asientosSeleccionadosStorage()['vuelta'];
	let paradas = localStorage.getItem('paradas') ? JSON.parse(localStorage.getItem('paradas')) : {ida:null, vuelta: null}

    ida.forEach(elem => {
		id_list.push(`${elem.idreserve}, ${this.resultadosBusquedaStorage()['ida'][0].destino}, ${paradas.ida}, ${elem.ticketid};`);
    });

    vuelta.forEach(elem => {
		id_list.push(`${elem.idreserve}, ${this.resultadosBusquedaStorage()['ida'][0].origen}, ${paradas.vuelta}, ${elem.ticketid};`);
	});

    return id_list
  }

}
