import { trigger, transition, style, animate, state, query, animateChild } from '@angular/animations';

export const fadeWindow = trigger('fadeWindow', [
	state('void', style({
		zIndex: 1299,
		marginTop: '-1em',
		opacity: 0,
	})),

	transition(':enter, :leave', [
		animate('0.2s')
	])
])

export const fade = trigger('fade', [
	state('void', style({
		opacity: 0,
	})),

	transition(':enter, :leave', [
		animate('0.2s')
	])
])

export const slideFade = trigger('slideFade', [
	state('void', style({
		transform: 'translateX(1em)',
		opacity: 0.5,
	})),

	transition(':enter, :leave', [
		animate('0.2s')
	])
])
