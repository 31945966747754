import { Component, OnInit, Input, Output,EventEmitter } from '@angular/core';
import { Servicio, Asiento } from '../../providers/tiposDatos'
import { ComprarPasajeDataService } from '../providers/comprar-pasaje-data.service'
import { ServiciosDataService } from '../providers/servicios-data.service'
import { BuscarService } from '../../core/busqueda-pasaje/buscar.service'
import { slideFade } from '../../providers/animations'
import { PasajeServiciosDataService } from './pasaje-servicios-data.service';
import { BreadcrumbDataService } from '../providers/breadcrumb-data.service';

@Component({
  selector: 'pasaje-servicios',
  templateUrl: './pasaje-servicios.component.html',
  styleUrls: ['./pasaje-servicios.component.scss'],
	animations: [ slideFade ],
})
export class PasajeServiciosComponent implements OnInit {

	@Input() viaje:string
	// viaje:string = 'ida'

   constructor(
		 public data:ComprarPasajeDataService,
		 public serviciosData:ServiciosDataService,
		 public busquedaData:BuscarService,
		 public pasajeServiciosData:PasajeServiciosDataService,
		 public bcData:BreadcrumbDataService,
	 ) { }

   ngOnInit() {
		 this.bcData.breadcrumbCurrentStep = 0;
	 }


	 seleccionarServicio(resultado:any, tipo:string) {

		 const info = {
			 id: resultado.id,
			 idtramo: resultado.idtramo,
			 hora: resultado.hora,
			 tipo: tipo,
			 precio: resultado[tipo]
		 }

		 if(this.viaje == 'ida') {
			 this.data.serviciosSeleccionados = {ida:null, vuelta:null}
		 }

		 this.data.serviciosSeleccionados[this.viaje] = info;
		 this.data.asientosSeleccionados.idtramo[this.viaje] = resultado.idtramo;

		 localStorage.setItem('serviciosSeleccionados', JSON.stringify(this.data.serviciosSeleccionados));

		 this.moverScrollbar();

 	}


	formatearNumero = function(numero:string):string {
		return parseInt(numero).toLocaleString('de-DE', {minimumFractionDigits:0})
	}


	moverScrollbar() {
		if(this.viaje == 'ida') {

			if(this.data.resultadosBusqueda.vuelta) {
				this.pasajeServiciosData.cambiarIdaAVuelta();

				let ref = document.getElementById('comprar-pasaje-top');
				ref.scrollIntoView({behavior: 'smooth'});
			}
		}
	}


}
