import { Injectable } from '@angular/core';
import { ComprarPasajeDataService } from './comprar-pasaje-data.service';
import { RealizarCompraService } from './realizar-compra.service';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbDataService {
	public breadcrumbCurrentStep:number = 0;

  constructor(
	  private data:ComprarPasajeDataService,
		private realizarCompra:RealizarCompraService,
	){}

	activo = function() : boolean[] {
		return [
			this.confirmDatosDelPaso1(),
			this.confirmDatosDelPaso2() ? true : false,
			this.data.datosDelCliente.valid && this.data.validateEmailConfirmControl() && this.confirmDatosDelPaso2() ? true : false,
			this.data.datosDelCliente.valid && this.data.validateEmailConfirmControl() && this.confirmDatosDelPaso2() ? true : false,
		]
	}


	confirmDatosDelPaso1():boolean {
		const data = localStorage.getItem('serviciosSeleccionados') ? JSON.parse(localStorage.getItem('serviciosSeleccionados')) : null;

		if(data) {
			let hayIda = data['ida'] ? true : false
			let hayVuelta = data['vuelta'] ? true : false
			let resultVuelta = this.data.resultadosBusqueda.vuelta ? true : false;

			if(resultVuelta) return hayVuelta;
			else return hayIda;
		}

		else return false;
	}


	confirmDatosDelPaso2():number {
		if(localStorage.getItem('asientosSeleccionados')) {
			const asientosSeleccionados = JSON.parse(localStorage.getItem('asientosSeleccionados')).data;

			if(asientosSeleccionados.idtramo.vuelta) {
				if(asientosSeleccionados.ida.length && asientosSeleccionados.vuelta.length) return 1;
				else return 0;
			}

			else {
				return asientosSeleccionados.ida.length
			}
		}

		else return 0;
	}
}
