import { WebServices } from 'src/app/providers/webServices';
import { Component, OnInit, ElementRef } from '@angular/core';
import { NgbCalendar, NgbDateParserFormatter, NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { BuscarService } from './buscar.service';
import { FormatoFechaService, DatepickerEsp } from '../../providers/formatoFecha.service';
import { ComprarPasajeDataService } from 'src/app/comprar-pasaje/providers/comprar-pasaje-data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { BreadcrumbDataService } from 'src/app/comprar-pasaje/providers/breadcrumb-data.service';
import { TimerService } from '../header/timer/timer.service';
import { IdSesionService } from 'src/app/providers/id-sesion.service';
import { ListaCiudadesService } from '../ciudades/lista-ciudades.service';

@Component({
  selector: 'busqueda-pasaje',
  templateUrl: './busqueda-pasaje.component.html',
  styleUrls: ['./busqueda-pasaje.component.scss'],
  providers: [
    { provide: NgbDateParserFormatter, useClass: FormatoFechaService },
    { provide: NgbDatepickerI18n, useClass: DatepickerEsp }
  ],
})
export class BusquedaPasajeComponent implements OnInit {
  test = 35000

  public abrirCiudadOrigen: boolean = false;
  public abrirCiudadDestino: boolean = false;
  public mensajeError: string
  public hoy = this.calendar.getToday()
  public SiguienteAnno = { year: this.hoy.year + 1, month: this.hoy.month, day: this.hoy.day }

  // DATA Y ESTADO "FOCUS" DEL <input>
  public busquedaInputs: any[] = [
    { data: "", dataToShow: "", hasData: false, isValid: true },
    { data: "", dataToShow: "", hasData: false, isValid: true },
    { data: "", hasData: false, isValid: true },
    { data: "", hasData: false, isValid: true },
  ]

  constructor(
    public calendar: NgbCalendar,
    public elementRef: ElementRef,
    public data: ComprarPasajeDataService,
    public busquedaData: BuscarService,
    public bcData: BreadcrumbDataService,
    public router: Router,
    public route: ActivatedRoute,
    public timerData: TimerService,
    public webServices: WebServices,
    public idSesionService: IdSesionService,
    public listaCiudades: ListaCiudadesService
  ) { }


  ngOnInit() {
    if (this.route.snapshot.queryParamMap.has('ciudad_or')) {
      this.searchByQueryParams();
    }

    this.listaCiudades.cargarCiudades();
  }

  searchByQueryParams() {
    const fechaFormateada = (fecha: any) => {
      fecha = fecha.split("-")
      console.log(fecha);
      return {
        day: Number(fecha[0]),
        month: Number(fecha[1]),
        year: Number(fecha[2]),
      }
    }

    if (this.route.snapshot.queryParamMap.has('ciudad_or')) {
      this.busquedaInputs[0] = {
        data: this.route.snapshot.queryParamMap.get('ciudad_or'),
        dataToShow: this.route.snapshot.queryParamMap.get('ciudad_or_name'),
        hasData: true,
        isValid: true
      }
    }

    if (this.route.snapshot.queryParamMap.has('ciudad_de')) {
      this.busquedaInputs[1] = {
        data: this.route.snapshot.queryParamMap.get('ciudad_de'),
        dataToShow: this.route.snapshot.queryParamMap.get('ciudad_de_name'),
        hasData: true,
        isValid: true
      }
    }

    if (this.route.snapshot.queryParamMap.has('fecha_or')) {
      this.busquedaInputs[2] = {
        data: fechaFormateada(this.route.snapshot.queryParamMap.get('fecha_or')),
        hasData: true,
        isValid: true
      }
    }

    if (this.route.snapshot.queryParamMap.has('fecha_de')) {
      this.busquedaInputs[3] = {
        data: fechaFormateada(this.route.snapshot.queryParamMap.get('fecha_de')),
        hasData: true,
        isValid: true
      }
    }

    if (!this.webServices.enElSitio) {
      this.webServices.enElSitio = true;
      this.buscarPasajes();
    }
  }



  // INTERCAMBIAR DATA DE DESTINO Y ORIGEN
  cambiarDestinoPorOrigen() {
    let _temp_data: string = this.busquedaInputs[0].data
    let _temp_dataToShow: string = this.busquedaInputs[0].dataToShow

    this.busquedaInputs[0].dataToShow = this.busquedaInputs[1].dataToShow
    this.busquedaInputs[0].data = this.busquedaInputs[1].data
    this.busquedaInputs[1].data = _temp_data
    this.busquedaInputs[1].dataToShow = _temp_dataToShow
  }


  verificarLongitudFechas() {
    if (this.busquedaInputs[3].data) {
      const tiempoIda = new Date(this.busquedaInputs[2].data.year + '/' + this.busquedaInputs[2].data.month + '/' + this.busquedaInputs[2].data.day).getTime();
      const tiempoVuelta = new Date(this.busquedaInputs[3].data.year + '/' + this.busquedaInputs[3].data.month + '/' + this.busquedaInputs[3].data.day).getTime();

      if (tiempoVuelta < tiempoIda) {
        this.busquedaInputs[3].data = {
          year: this.busquedaInputs[2].data.year,
          month: this.busquedaInputs[2].data.month,
          day: this.busquedaInputs[2].data.day,
        };
      }
    }
  }


  // ASIGNAR
  campoTieneInfo(campo) {
    if (this.busquedaInputs[campo].hasData) return true;
    else return false;
  }


  // VALIDACIÓN DE FORMULARIO... RETORNA true SI ES VÁLIDO, SI NO, INVALIDA LOS CAMPOS QUE ESTÉN VACÍOS
  formularioValido(): boolean {
    this.busquedaInputs.forEach((invalido, i) => i < 3 ? invalido.isValid = false : true)

    let validado = this.busquedaInputs.filter(input => input.data)

    validado.forEach(valido => valido.isValid = true)

    if (validado.length >= 3) return true
  }


  initBusquedaParams() {
    // this.timerData.rebootTimerData();

    this.mensajeError = '';

    this.data.serviciosSeleccionados = { ida: null, vuelta: null };
    this.data.resultadosBusqueda = { ida: null, vuelta: null };

    this.busquedaData.buscandoServicios = true;
    this.busquedaData.pasajeServicioData.enIda = true;
    this.busquedaData.pasajeServicioData.enIdaOcultar = false;
  }

  // ENVÍO DE FORMULARIO PARA LA BÚSQUEDA DE PASAJES
  buscarPasajes() {
    if (this.formularioValido()) {

      this.initBusquedaParams();
      this.almacenarDatosDeBusqueda();

      this.bcData.breadcrumbCurrentStep = 0;
      this.busquedaData.cargarIdaVuelta();

      let i = 0;

      const i_cargarResultados = setInterval(() => {
        if (this.data.resultadosBusqueda.ida) {
          this.router.navigate(['comprar-pasaje/p0']);
          clearInterval(i_cargarResultados);
        }

        else if (i == 1000) {
          clearInterval(i_cargarResultados);
        }

        i++;
      }, 300)

    }

    else {
      this.mensajeError = 'Por favor, llena todos los campos'
    }
  }


  verResultadosBusqueda = new Promise((resolve, reject) => {

  })


  // CIERRA EL COMPONENTE QUE MUESTRA LA LISTA DE CIUDADES
  cerrarListaCiudades(e: Event) {
    let lista;

    if (this.abrirCiudadOrigen) {
      lista = this.elementRef.nativeElement.querySelector('#busqpsj-origen')
    }

    else if (this.abrirCiudadDestino) {
      lista = this.elementRef.nativeElement.querySelector('#busqpsj-destino')
    }

    if (e.target != lista) {
      this.abrirCiudadOrigen = false
      this.abrirCiudadDestino = false
    }
  }

  public llenarDatosOrigenDestino(event, index: number) {
    this.busquedaInputs[index].data = event.id
    this.busquedaInputs[index].dataToShow = event.ciudad

    this.abrirCiudadOrigen = false
    this.abrirCiudadDestino = false
  }

  public almacenarDatosDeBusqueda() {

    let fechaIda: string = `${this.busquedaInputs[2].data.day}-${this.formatearMes(this.busquedaInputs[2].data.month)}-${this.busquedaInputs[2].data.year}`;
    let fechaVuelta: string = this.busquedaInputs[3].data ? `${this.busquedaInputs[3].data.day}-${this.formatearMes(this.busquedaInputs[3].data.month)}-${this.busquedaInputs[3].data.year}` : null;

    this.busquedaData.busquedaData = {
      fechaIda: fechaIda,
      fechaVuelta: fechaVuelta,
      origen: this.busquedaInputs[0].data,
      destino: this.busquedaInputs[1].data,
    }

  }

  public formatearMes(mes: number): string {
    if (mes < 10) return '0' + mes
    else return mes.toString()
  }

}
