import { Injectable } from '@angular/core';
import { ComprarPasajeDataService } from './comprar-pasaje-data.service'
import Swal from 'sweetalert2'

@Injectable({
  providedIn: 'root'
})
export class RealizarCompraService {

	// public Swal = require('sweetalert2')

	public mensajeError : string;
	formularioValidoConfirm = this.data.datosDelCliente.valid == true ? true : false;

  constructor(public data : ComprarPasajeDataService) { }


	// formularioValido() : boolean {
	//   let validado:number = 1;

	//   this.data.datosDelCliente.forEach(campo => {
	//     if(campo.isValid == true) validado++
	// 		else if(campo.isValid == false) validado--
	//   })

	// 	if(validado >= this.data.datosDelCliente.length) return true
	// 	else return false
	// }


	comprarPasajes() {
		this.data.compraConfirm = true;

		Swal({
			title: '¡Compra realizada exitosamente!',
			type: 'success',
			confirmButtonText: '<i class="fas fa-file-download"></i> Descargue su recibo'
		})
	}
}
