import { Injectable } from '@angular/core';
import { ComprarPasajeDataService } from 'src/app/comprar-pasaje/providers/comprar-pasaje-data.service';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2'

import xml2js from 'xml2js';
import { PasajeServiciosDataService } from 'src/app/comprar-pasaje/pasaje-servicios/pasaje-servicios-data.service';
import { ServiciosDataService } from 'src/app/comprar-pasaje/providers/servicios-data.service';
import { AlertasService } from 'src/app/providers/alertas.service';
import { IdSesionService } from 'src/app/providers/id-sesion.service';

@Injectable({
    providedIn: 'root'
  })
export class BuscarService
{
	public busquedaData:any = {
		fechaIda:'',
		fechaVuelta:'',
		origen:'',
		destino:'',
	}

	public buscandoServicios:boolean;

	public lista:any;

	constructor (
		public data:ComprarPasajeDataService,
		public http:HttpClient,
		public pasajeServicioData:PasajeServiciosDataService,
		public serviciosData:ServiciosDataService,
		public alerta:AlertasService,
		public idsesionService: IdSesionService
	) {}

	public cargar(viaje:string) {
		if(this.busquedaData.fechaIda && this.busquedaData.origen && this.busquedaData.destino) {
			localStorage.setItem('busquedaData', JSON.stringify(this.busquedaData))
			this.data.initCompra = false;

			let url = `https://ptur-ws-web.azurewebsites.net/ws_external/api/pullmantur/servicio.php?WSDL`;

			this.http.post( url, this.busquedaDataPOST(viaje), { headers: this.serviciosData.headers, responseType:'text' })
			.subscribe(
				data => {
					this.buscandoServicios = false
					let _data = JSON.parse(this.xmlToJSON(data)['SOAP-ENV:Envelope']['SOAP-ENV:Body'][`ns1:ws_horariosResponse`]['return']['_'])

					if(_data['resultado'][0]['data'] == 1) {
						this.data.initCompra = true;
						this.data.resultadosBusqueda[viaje] = _data['resultado'][0]['response'];

						const data_a_guardar = JSON.stringify({data: this.data.resultadosBusqueda, hora: new Date().getTime()});
						localStorage.setItem('resultadosBusqueda', data_a_guardar)
					}

					else {
						this.mensajeDeError(viaje);
					}

				},

				(error:HttpErrorResponse) => {
					this.buscandoServicios = false

          this.alerta.custom(
            'SAD_Emoji.svg',
            'No hemos podido conectarnos con Pullman Tur',
            '<i class="fas fa-arrow-left"></i> Intenta de nuevo',
            'error'
          )

					console.error(error)
				}
			)
		}

	}


	public xmlToJSON(data) {
		let stringJson

		xml2js.parseString(data, {explicitArray:false}, (error, result)=> {
			stringJson = result
		});
		return stringJson;
	}


	public busquedaDataPOST = (viaje) => {
		return `
		<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/">
			<soapenv:Body>
				<ws_horarios>
					<api>PULLM4N_TURCL</api>
					<fecha>${viaje == 'vuelta' ? this.busquedaData.fechaVuelta : this.busquedaData.fechaIda }</fecha>
					<origen>${viaje == 'vuelta' ? this.busquedaData.destino : this.busquedaData.origen}</origen>
					<destino>${viaje == 'vuelta' ? this.busquedaData.origen : this.busquedaData.destino}</destino>
				</ws_horarios>
			</soapenv:Body>
		</soapenv:Envelope>
		`
	}


	public cargarIdaVuelta() {
		this.buscandoModal();

		this.cargar('ida');

		if(this.busquedaData.fechaVuelta) {
			this.cargar('vuelta')
		}

		this.buscandoModal();
	}


	buscandoModal() {
		this.alerta.customLoading('PT_ISOTIPO.svg', 'Buscando pasajes...')

		let i = setInterval(() => {
			if(!this.buscandoServicios) {
				Swal.close();
				clearInterval(i);
			}
		}, 500)
	}


	mensajeDeError(viaje) {
		setTimeout(() => {
			if(viaje == 'vuelta') {
				this.data.resultadosBusqueda['vuelta'] = null;

        this.alerta.custom(
          'Exclamation_Icon.svg',
          'Sólo se encontraron viajes de IDA',
          'Continuar <i class="fas fa-arrow-right"></i>',
          'info'
        )
			}

			else {
				this.data.resultadosBusqueda['ida'] = null;
				this.data.resultadosBusqueda['vuelta'] = null;

        this.alerta.custom(
          'SAD_Emoji.svg',
          'No se encontraron resultados en la búsqueda',
          '<i class="fas fa-arrow-left"></i> Intenta de nuevo',
          'error'
        )

			}

		}, 600)
	}


}
