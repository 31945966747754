import { Component, OnInit, AfterViewChecked, ElementRef, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { ComprarPasajeDataService } from '../providers/comprar-pasaje-data.service';
import { RealizarCompraService } from '../providers/realizar-compra.service';
import { PasajeServiciosDataService } from '../pasaje-servicios/pasaje-servicios-data.service';
import { BreadcrumbDataService } from '../providers/breadcrumb-data.service';
import { WebServices } from 'src/app/providers/webServices';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { LoginService } from 'src/app/core/login/login.service';

@Component({
  selector: 'cuadro-total',
  templateUrl: './cuadro-total.component.html',
  styleUrls: ['./cuadro-total.component.scss']
})
export class CuadroTotalComponent implements OnInit, AfterViewChecked {

	@Output() enIda = new EventEmitter;
	@Output() enIdaOcultar = new EventEmitter;

	public enIda_placeholder:boolean = true;
	public enIdaOcultar_placeholder:boolean = false;
	public paradas = () => localStorage.getItem('paradas') ? JSON.parse(localStorage.getItem('paradas')) : null;


	public botonComprarInit:boolean

	constructor(
		public data:ComprarPasajeDataService,
		public realizarCompra:RealizarCompraService,
		public router:Router,
		public pasajeServiciosData:PasajeServiciosDataService,
		public bcData:BreadcrumbDataService,
		public elementRef:ElementRef,
		public changeDetectionRef:ChangeDetectorRef,
	    public webServices:WebServices,
		public http:HttpClient,
		public login: LoginService
	) {
	}


  ngOnInit() {
  }


	ngAfterViewChecked() {
		this.changeDetectionRef.detectChanges();
	}


	irASiguientePaso() {
		let siguientePaso:number = this.bcData.breadcrumbCurrentStep < 4 ? this.bcData.breadcrumbCurrentStep + 1 : this.bcData.breadcrumbCurrentStep
		const ruta = `comprar-pasaje/p${siguientePaso}`;

		if(this.bcData.activo()[this.bcData.breadcrumbCurrentStep] == true) {
			this.router.navigate([ruta]);
			this.moverScrollbar();
		}

	}


	moverScrollbar() {
		const ref = document.getElementById('comprar-pasaje-top');
		ref.scrollIntoView({behavior: 'smooth'});
	}


	cambiarIdaAVuelta() {
		this.pasajeServiciosData.enIda = !this.pasajeServiciosData.enIda;

		setTimeout(() => {
			this.pasajeServiciosData.enIdaOcultar = !this.pasajeServiciosData.enIdaOcultar;
		}, 1000)
	}


  tomarTipoPago(radio:any) {
    this.data.tipopago = radio.value;
  }

  enviarTipoPago() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    })

    this.http.post(
      'https://ptur-ws-web.azurewebsites.net/medio_pago.php',
      JSON.stringify({tipopago: this.data.tipopago, idSession: this.webServices.idSesion}),
      {headers:headers}
    ).
    subscribe(
      data => {},
      (err:HttpErrorResponse) => {console.error(err)}
    )
	}

	regenerarIDsession(){
		this.webServices.get('getIdSession', ()=>{
			this.webServices.idSesion = this.webServices._temp_['id']
			localStorage.setItem('idSesion', JSON.stringify({id:this.webServices._temp_.idSesion, hora: new Date().getTime()}))
		});
	}

	enviar(){
	}

}
