import { Component, OnInit } from '@angular/core';
import { TimerService } from './timer.service';

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss']
})
export class TimerComponent implements OnInit {

	timerFormato = () => `${this.timerService.minutos == 10 ? 10 : '0' + this.timerService.minutos}:${this.timerService.segundos >= 10 ? this.timerService.segundos : '0' + this.timerService.segundos }`

	constructor(
		public timerService: TimerService
	) { }

  ngOnInit() {
		this.timerService.initTemporizador();
  }
}
