import { Component, OnInit } from '@angular/core';
import { ComprarPasajeDataService } from '../providers/comprar-pasaje-data.service'
import { slideFade } from '../../providers/animations'
import { BuscarService } from '../../core/busqueda-pasaje/buscar.service'
import { PasajeServiciosDataService } from '../pasaje-servicios/pasaje-servicios-data.service';
import { TimerService } from 'src/app/core/header/timer/timer.service';
import { IdSesionService } from 'src/app/providers/id-sesion.service';

@Component({
  selector: 'app-pasaje-servicios-ida-vuelta',
  templateUrl: './pasaje-servicios-ida-vuelta.component.html',
  styleUrls: ['./pasaje-servicios-ida-vuelta.component.scss'],
	animations: [ slideFade ],
})
export class PasajeServiciosIdaVueltaComponent implements OnInit {

  constructor(
		public data:ComprarPasajeDataService,
		public busquedaData:BuscarService,
		public pasajeServiciosData:PasajeServiciosDataService,
		public timerService:TimerService,
		public idsesionService: IdSesionService
	) { }

  ngOnInit() {
		this.pasajeServiciosData.enIda = true;
		this.pasajeServiciosData.enIdaOcultar = false;
		this.data.compraDataReset();
	    this.resetLocalData()
	}
	
	resetLocalData() {
	//   localStorage.removeItem('idSesion');
	//   localStorage.removeItem('seat_map_DATA');
	//   localStorage.removeItem('compra_timer');
	  localStorage.removeItem('paradas');
	//   localStorage.removeItem('resultadosBusqueda');
	//   localStorage.removeItem('serviciosSeleccionados');
	  this.idsesionService.crearSesionID();
  }
  
}
