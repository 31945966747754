import { RegistroService } from './../providers/registro.service';
import { Component, OnInit } from '@angular/core';
import { Registro } from 'src/app/providers/tiposDatos';
import { FormGroup, FormControl, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2'
// import * as format from 'date-fns/format'

@Component({
  selector: 'registro-usuario',
  templateUrl: './registro.component.html',
  styleUrls: ['./registro.component.scss']
})
export class RegistroComponent implements OnInit {

  datosUsuario: Registro;
  public currentModal:any;
  public today: Date;
  public hora;
  public formRegistro: FormGroup;
  constructor(
    private registro: RegistroService,
    private formBuilder: FormBuilder,
    public activeModal:NgbActiveModal,
    public modal: NgbModal

  ) {
    this.today = new Date('28/12/2018');

    this.formRegistro = this.formBuilder.group({
      name: ['',Validators.compose([Validators.required, Validators.pattern('[a-zA-Z ]*')])],
      lastname: ['',Validators.compose([Validators.required, Validators.pattern('[a-zA-Z ]*')])],
      idDocument: ['', Validators.compose([Validators.required, Validators.minLength(7), Validators.maxLength(8), Validators.pattern("[0-9]+")])],
      typeDocument:['R.u.n'],
      phone:['',Validators.compose([Validators.required, Validators.minLength(9), Validators.pattern("[0-9]+")])],
      email: ['',Validators.compose([Validators.required, Validators.email])],
      password: ['',[Validators.required, Validators.minLength(8)]],
    });

  }

  ngOnInit() {
   
  }
  

  onSubmit(){

  }

  registrarUsuario()
  {
    this.registro.usuarioNuevo(this.formRegistro.value).then(data =>{
      this.cerrarModal();
    });

  }

  cerrarModal(){
    this.activeModal = this.currentModal;
    this.activeModal.close();
  }

}
